import {
  Button,
  HStack,
  Text,
  VStack,
  Center,
  Box,
  Input,
  TextArea,
} from "native-base"
import React, { useEffect, useState } from "react"
import CredentialHOC from "./action"
import _ from "lodash"

const ChangeEmail = (props) => {
  const [email, setEmail] = useState("")
  const [err, setErr] = useState("")
  useEffect(()=>{
    setEmail(props.reduxData.user.email)
  },[])
  return (
    <VStack bg={ 'black' } height={ '100%' }>
      <Center flex={1}>
        <Box bg="#555" w="80%" mb="10px" borderRadius="20px" p={3}>
          <VStack space={2}>
            <Text color="#fff" fontSize="xl">Change Email</Text>
            <HStack alignItems="center">
              <Text color="#fff" flex={1}>
                Email
              </Text>
              <VStack flex={2}>
                <Input
                  variant="filled"
                  placeholder="Email"
                  value={email}
                  type="email"
                  onChangeText={(value) =>
                    setEmail(value)
                  }
                />
              </VStack>
            </HStack>
          </VStack>
          {err && (
            <Text color="#ff0000" fontSize="12px">
              {err}
            </Text>
          )}
        </Box>
        <Button w="80%" colorScheme="success" onPress={() => {
          if( email !== "" ){
            setErr("")
            props.updateEmail(email)
          } else {
            setErr("This field is required")
          }
        }}>
          Submit
        </Button>
      </Center>
    </VStack>
  )
}

export default CredentialHOC(ChangeEmail)
