import React from "react"
import { NativeBaseProvider, Box } from "native-base"
import { NavigationContainer, DefaultTheme, useNavigationContainerRef } from "@react-navigation/native"
import { createStackNavigator } from "@react-navigation/stack"
import { Provider } from "react-redux"
import { createStore } from "redux"
import Login from "./containers/Login"
import Main from "./containers/Main"
import { initializeApp } from 'firebase/app'
import dataReducer from "./reducers"
import ForgetPassword from "./containers/ForgetPassword"
import NewPassword from "./containers/ForgetPassword/newPassword"
import { LogBox } from 'react-native';
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
LogBox.ignoreAllLogs()

const firebaseConfig = {
  apiKey: "AIzaSyA13FYM8iuXzuejMTevFRb0NAjkmUqtgxs",
  authDomain: "distributor-system-bc214.firebaseapp.com",
  projectId: "distributor-system-bc214",
  storageBucket: "distributor-system-bc214.appspot.com",
  messagingSenderId: "219152519416",
  appId: "1:219152519416:web:228204d73aaebe390ceb0d",
  measurementId: "G-GX8B6MWK6R"
};
initializeApp(firebaseConfig);


const AppStack = createStackNavigator()
const store = createStore(dataReducer)

const NavigationTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: "transparent",
  },
}

const config = {
  animation: "spring",
  config: {
    stiffness: 1000,
    damping: 50,
    mass: 3,
    overshootClamping: false,
    restDisplacementThreshold: 0.01,
    restSpeedThreshold: 0.01,
  },
}

const styleConfig = {
  dependencies: {
    'linear-gradient': require('expo-linear-gradient').LinearGradient
  }
}

export default function App() {
  const navigationRef = useNavigationContainerRef()
  return (
    <Provider store={store}>
      <NativeBaseProvider config={styleConfig}>
        <Box bg="gray.900" safeArea flex={1}>
        <NavigationContainer theme={NavigationTheme} ref={navigationRef}>
          <AppStack.Navigator
            screenOptions={{
              gestureEnabled: true,
              gestureDirection: "horizontal",
              transitionSpec: {
                open: config,
                close: config,
              },
            }}
            animation="fade"
          >
            <AppStack.Screen
              name="login"
              component={Login}
              options={{ headerShown: false }}
            />
            <AppStack.Screen
              name="main"
              component={Main}
              options={{ headerShown: false }}
              initialParams={{ navigationRef: navigationRef }}
            />
            <AppStack.Screen
              name="forget_password"
              component={ForgetPassword}
              options={{ headerShown: false }}
              initialParams={{ navigationRef: navigationRef }}
            />
            <AppStack.Screen
              name="new_password"
              component={NewPassword}
              options={{ headerShown: false }}
              initialParams={{ navigationRef: navigationRef }}
            />
          </AppStack.Navigator>
        </NavigationContainer>
        </Box>
      </NativeBaseProvider>
    </Provider>
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();