import React, { useEffect, useState } from "react"
import {
  Text,
  FlatList,
  Image,
  HStack,
  VStack,
  Box,
  Button,
} from "native-base"
import { RefreshControl } from 'react-native'
import { compose } from "redux"
import moment from "moment"
import { useNavigation } from "@react-navigation/native"
import ReorderConfirmation from "./ReorderConfirmation"
import OrderHistoryHOC from "./action"
import OrderHOC from "../actions"

const OrderHistory = (props) => {
  const navigation = useNavigation()
  const [showModal, setShowModal] = useState(false)
  const [selectedProductSet, setSelectedProductSet] = useState([])
  const [selectedType, setSelectedType] = useState("paid")

  useEffect(() => {
    onRefresh()
  }, [])

  const onRefresh = () => {
    props.getOrderHistory()
    if (props.reduxData.user && [ 'Retailer', 'Branch' ].includes( props.reduxData.user.role )) {
      props.getPendingPaymentHistory()
    }
  }

  return (
    <VStack bgColor={ 'black' } height={ '100%' }>
      {
        props.reduxData.user && [ 'Retailer', 'Branch' ].includes( props.reduxData.user.role ) && (
          <HStack>
            <Button
              flex={1}
              variant="ghost"
              borderRadius="0"
              onPress={() => {
                setSelectedType("paid")
              }}
              borderBottomColor = "#4166f5"
              borderBottomWidth = {selectedType === "paid" ? "2px" : "0px"}
            >
              <Text color="#fff">
                Paid Order
              </Text>
            </Button>
            <Button
              flex={1}
              variant="ghost"
              borderRadius="0"
              onPress={() => {
                setSelectedType("pay-later")
              }}
              borderBottomColor = "#4166f5"
              borderBottomWidth = {selectedType === "pay-later" ? "2px" : "0px"}
            >
              <Text color="#fff">
                Pay-Later Order
              </Text>
            </Button>
          </HStack>
        )
      }
      <FlatList
        data={
          selectedType === "paid"
            ? props.reduxData.orderHistory
            : props.reduxData.pendingPaymentHistory
        }
        px={3}
        refreshControl={ <RefreshControl refreshing={ props.onLoadOrderHistory } onRefresh={ onRefresh } /> }
        ListEmptyComponent={ <Text color="white" textAlign="center" mt={ 5 } >No Order History</Text> }
        keyExtractor={(item) => item.order_id.toString()}
        renderItem={({ item }) => (
          <VStack mb={3} flex={1}>
            <HStack space={1} alignItems="center">
              <Text color="#fff" bold fontSize="16px">
                {moment(new Date(selectedType === "paid" ? item.transaction_date : item.order_date)).format("DD/MM/YYYY")}
              </Text>
              <Text color="#fff" bold fontSize="11px">
                ({item.order_id})
              </Text>
            </HStack>
            <Box
              w="100%"
              backgroundColor="rgba(255,255,255, 0.1)"
              borderRadius={20}
              px={3}
              py={5}
            >
              {item.items.map((orderItem) => {
                return (
                  <HStack my={1} space={2}>
                    <Image
                      size={100}
                      bg="#BEBEBE"
                      alt="Product Image"
                      source={{
                        uri: orderItem.src
                          ?  orderItem.src
                          : "https://icons-for-free.com/download-icon-mountains+photo+photos+placeholder+sun+icon-1320165661388177228_512.png",
                      }}
                    />
                    <HStack flex={1}>
                      <VStack flex={3}>
                        <Text color="#fff" bold>
                          {orderItem.product}
                        </Text>
                        <HStack alignItems="flex-end">
                          <Text color="#fff" fontSize="12px">
                            RM {orderItem.dist_price + " "}
                          </Text>
                          <Text fontSize="10px" color="#fff">
                            /item
                          </Text>
                        </HStack>
                      </VStack>
                      <Text color="#fff" flex={1}>
                        x {orderItem.quantity}
                      </Text>
                      <Text color="#fff" flex={2} bold>
                        RM{" "}
                        {(
                          orderItem.quantity * parseFloat(orderItem.dist_price)
                        ).toFixed(2)}
                      </Text>
                    </HStack>
                  </HStack>
                )
              })}
              <HStack flex={1}>
                <Text
                  flex={1}
                  fontSize="lg"
                  textAlign="right"
                  bold
                  color="#fff"
                >
                  Order Status:
                </Text>
                <Text
                  flex={1}
                  fontSize="lg"
                  textAlign="right"
                  bold
                  color={
                    item.delivery_status === "Paid"
                      ? "#8080ff"
                      : item.delivery_status === "Cancelled"
                      ? "#ff0000"
                      : item.delivery_status === "Delivered"
                      ? "#00ff00"
                      : "#fff"
                  }
                >
                  {item.delivery_status}
                </Text>
              </HStack>
              <HStack flex={1} pb="4">
                <Text
                  flex={1}
                  fontSize="lg"
                  textAlign="right"
                  bold
                  color="#fff"
                >
                  Total Payment:
                </Text>
                <Text
                  flex={1}
                  fontSize="lg"
                  textAlign="right"
                  bold
                  color="#fff"
                >
                  RM {parseFloat(item.total_price).toFixed(2)}
                </Text>
              </HStack>
              {selectedType === "paid" && (
                <Button
                  variant="outline"
                  colorScheme="teal"
                  onPress={() => {
                    setShowModal(true)
                    setSelectedProductSet(item.items)
                  }}
                >
                  Reorder
                </Button>
              )}
              {selectedType === "pay-later" && (
                  <Button
                    disabled={item.status !== "Pending Payment"}
                    variant="outline"
                    colorScheme="teal"
                    onPress={() => {
                      navigation.navigate("checkout", {
                        checkoutItems: item.items,
                        subtotal: item.total_price,
                        deliverAddress: item.delivery_address,
                        orderId: item.order_id
                      })
                    }}
                  >
                    { item.status === "Pending Payment" ? "Make Payment" : "Payment Done"}
                  </Button>
                )}
            </Box>
          </VStack>
        )}
      />
      <ReorderConfirmation
        isOpen={showModal}
        setShowModal={setShowModal}
        productSet={selectedProductSet}
        addToCart={props.addToCart}
      />
    </VStack>
  )
}

export default compose(OrderHistoryHOC, OrderHOC)(OrderHistory)
