import React from "react"
import {
  Modal,
  Button,
  Image,
  Center,
  VStack,
  HStack,
  Text,
  Box,
} from "native-base"

const ReorderConfirmation = (props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={() => props.setShowModal(false)}>
      <Modal.Content >
        <Modal.CloseButton />
        <Modal.Header>Reorder Products</Modal.Header>
        <Modal.Body>
          <Text>Confirm on adding this set of products to carts?</Text>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button
              variant="ghost"
              colorScheme="blueGray"
              onPress={() => {
                props.setShowModal(false)
              }}
            >
              Close
            </Button>
            <Button
              onPress={() => {
                props.productSet.map( async product =>{
                  let tmp = { product_id: product.item_id }
                  await props.addToCart( tmp.product_id, product.quantity )
                  props.setShowModal(false)
                })
              }}
              colorScheme="red"
            >
              Add to Cart
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}

export default ReorderConfirmation
