import React from "react"
import { Spinner, Heading, Center, Box } from "native-base"

const LoadingModal = (props) => {
  return (
    <Box position="absolute" w="100%" h="100%" flex="1" bg="rgba(0,0,0,0.5)">
      <Center flex="1">
        <Spinner accessibilityLabel="Loading posts" size="lg" />
        <Heading color="primary.500" fontSize="lg">
          {props.message ? props.message : "Loading"}
        </Heading>
      </Center>
    </Box>
  )
}

export default LoadingModal
