import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from "react-redux"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { Get } from '../../utils/axios'
import LoadingModal from '../../components/LoadingModal'
import { updateData } from '../../action'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state={
      loading: false,
      user: {},
    }

    load = param => this.setState({ loading: param })
    
    onChangeLoginHOC = ( val, context ) => this.setState({ [ context ]: val})

    authenticateUser = () => {
      try {
        const auth = getAuth()
        onAuthStateChanged(auth, (user) => {
          if (user) {
            this.props.updateData('uid', user.uid )
            Get(
              `/getUser?uid=${ user.uid }`,
              payload => this.authenticateUserSuccess( payload, user.uid ),
              this.authenticateUserError,
              this.load
            )
          }
        })
      } catch {
        setTimeout(() => this.authenticateUser(), 10000)
      }
    }
    authenticateUserSuccess = ( payload, uid ) => {
      this.props.updateData('user', payload.data )
      this.getCart( uid )
      this.getProduct()
      this.getMaterial()
      this.getOrderHistory(uid)
      this.getPendingPaymentHistory(uid)
      this.props.navigation.navigate("main")
    }
    authenticateUserError = error => console.log( error )

    getCart = ( id ) => Get(
      `/getCartItems?order_by=${ id }`,
      this.getCartSuccess,
      this.getCartError,
      this.load
    )
    getCartSuccess = payload => {
      this.props.updateData('cart', payload.data )
    }
    getCartError = error => { console.log( error.message )}

    getProduct = () => Get(
      `/getProduct`,
      this.getProductSuccess,
      this.getProductError,
      this.load
    )
    getProductSuccess = payload => {
      this.props.updateData('product', payload.data.list )
    }
    getProductError = error => { console.log( error.message )}

    getMaterial = () => Get(
      `/getMaterial`,
      this.getMaterialSuccess,
      this.getMaterialError,
      this.load
    )
    getMaterialSuccess = payload => {
      this.props.updateData('material', payload.data )
    }
    getMaterialError = error => { console.log( error.message )}

    getOrderHistory = (uid) =>
      Get(
        `/getHistoryOrder?order_by=${uid}`,
        this.getOrderHistorySuccess,
        this.getOrderHistoryError,
        this.load
      )
    getOrderHistorySuccess = (payload) => {
      let tmp = _.orderBy(payload.data.history, (o) => {
        return new Date(o.transaction_date)
      }, ["desc"])
      this.props.updateData('orderHistory', tmp )
    }
    getOrderHistoryError = (error) => {
      console.log(error.message)
    }
    
    getPendingPaymentHistory = () =>
      Get(
        `/getPendingPaymentHistory?order_by=${this.props.reduxData.uid}`,
        this.getPendingPaymentHistorySuccess,
        this.getPendingPaymentHistoryError,
        this.load
      )
    getPendingPaymentHistorySuccess = (payload) => {
      console.log(payload.data)
      let tmp = _.orderBy(
        payload.data.history,
        (o) => {
          return new Date(o.order_date)
        },
        ["desc"]
      )
      this.props.updateData("pendingPaymentHistory", tmp)
    }
    getPendingPaymentHistoryError = (error) => {
      console.log(error)
    }

    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            onLoginLoad={ this.state.loading }

            getCart={ this.getCart }
            authenticateUser={ this.authenticateUser}
            onChangeLoginHOC={ this.onChangeLoginHOC }
          />
          {this.state.loading && <LoadingModal />}
        </>
      )
    }
  }

  const mapStateToProps = (state) => ({
    reduxData: state,
  })

  return connect(mapStateToProps, { updateData })(WithHOC)
}


export default HOC