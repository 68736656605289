import React from "react"
import {
  Modal,
  Button,
  Image,
  Center,
  VStack,
  HStack,
  Text,
  View,
  Box,
} from "native-base"
import Carousel from "react-native-snap-carousel"

const Material = (props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={() => props.setShowModal(false)}>
      <Modal.Content >
        <Modal.CloseButton />
        <Modal.Header>{props.item.material_name}</Modal.Header>
        <Modal.Body>
          <VStack flex={1}>
            <Center flex={1}>
              <Image
                size={125}
                mb={5}
                alt={props.item.name}
                source={{
                  uri: props.item.image_url ? props.item.image_url[0] : "https://icons-for-free.com/download-icon-mountains+photo+photos+placeholder+sun+icon-1320165661388177228_512.png",
                }}
              />
            </Center>
            <VStack pr={1} space={2}>
              <Box>
                <HStack space={2}>
                  <Text flex={1} bold>Name</Text>
                  <Text flex={2}>{props.item.material_name}</Text>
                </HStack>
              </Box>
              <Box>
                <HStack space={2} alignItems="center">
                  <Text flex={1} bold>Description</Text>
                  <Text flex={2}>{props.item.material_description}</Text>
                </HStack>
              </Box>
              <Box>
                <HStack space={2}>
                  <Text flex={1} bold>Category</Text>
                  <Text flex={2}>{props.item.category}</Text>
                </HStack>
              </Box>
              <Box>
                <HStack space={2}>
                  <Text flex={1} bold>Size</Text>
                  <Text flex={2}>{props.item.material_volume}</Text>
                </HStack>
              </Box>
              <Box>
                <HStack space={2} >
                  <Text flex={1} bold>Price</Text>
                  <Text flex={2}>RM {props.item.retail_price}</Text>
                </HStack>
              </Box>
              <Box>
                <HStack space={2} alignItems="center">
                <Text flex={1} bold>Distributor Price</Text>
                <Text flex={2}>RM {props.item.dist_price}</Text>
                </HStack>
              </Box>
              <Box>
                <HStack space={2}>
                  <Text flex={1} bold>Stock Left</Text>
                  <Text flex={2}>{props.item.stock}</Text>
                </HStack>
              </Box>
            </VStack>
          </VStack>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button
              variant="ghost"
              colorScheme="blueGray"
              onPress={() => {
                props.setShowModal(false)
              }}
            >
              Close
            </Button>
            <Button
              onPress={() => {
                props.addToCart( props.item.material_id )
                props.setShowModal(false)
              }}
              colorScheme="red"
            >
              Add to Cart
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}

export default Material
