import React, { useState, useEffect } from "react"
import _ from "lodash"
import {
  HStack,
  Checkbox,
  VStack,
  Text,
  FlatList,
  Image,
  Box,
  Input,
  Button,
  Icon,
  TextArea,
  IconButton,
} from "native-base"
import { RefreshControl } from "react-native"
import { AntDesign, Ionicons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"
import Swipeable from "react-native-gesture-handler/Swipeable"
import CartHOC from "./actions"

const Cart = (props) => {
  const navigation = useNavigation()
  const [itemData, setItemData] = useState([])
  const [editAddress, setEditAddress] = useState(false)
  const [deliverAddress, setDeliverAddress] = useState("")
  const [checkoutItems, setCheckoutItems] = useState([])
  const [subtotal, setSubtotal] = useState(0)

  useEffect(() => {
    if (props.reduxData.menuTitle === "cart") {
      props.getCart()
    }
  }, [props.reduxData.menuTitle])

  useEffect(() => {
    setItemData(
      props.reduxData.cart && props.reduxData.cart.data
        ? props.reduxData.cart.data
        : []
    )
    setDeliverAddress(props.reduxData.user.address || '')
  }, [props.reduxData.cart])

  useEffect(() => {
    calculateSum(checkoutItems)
  }, [checkoutItems])

  const onRefresh = () => {
    props.getCart()
  }

  const onCheck = (val, item) => {
    let temp = _.cloneDeep(checkoutItems)
    if (val) {
      temp.push(item)
    } else {
      temp = _.remove(temp, (i) => {
        return i.item_id !== item.item_id
      })
    }
    setCheckoutItems(temp)
  }

  const calculateSum = (data) => {
    let total = 0
    if (data.length > 0) {
      data.map((item) => {
        total += Number(item.dist_price) * item.quantity
      })
    }
    setSubtotal(total.toFixed(2).toString())
  }

  const checkAll = (val) => {
    if (val) setCheckoutItems(itemData)
    else setCheckoutItems([])
  }

  const onIncrement = (val) => {
    val.quantity++
    let index = _.findIndex(itemData, (i) => {
      return i.item_id === val.item_id
    })
    let checkoutIndex = _.findIndex(checkoutItems, (i) => {
      return i.item_id === val.item_id
    })
    let temp = _.cloneDeep(itemData)
    let temp2 = _.cloneDeep(checkoutItems)
    temp[index] = val
    setItemData(temp)
    props.updateData("cart", { data: temp })
    props.updateCart(val)
    if( Number(temp[index].quantity) <= Number(temp[index].stock) && checkoutIndex > -1) {
      temp2[checkoutIndex] = val
      setCheckoutItems(temp2)
    }
    if( Number(temp[index].quantity) > Number(temp[index].stock) && checkoutIndex > -1 ){
      temp2.splice( checkoutIndex, 1 )
      setCheckoutItems(temp2)
    }
  }

  const onDecrement = (val) => {
    val.quantity--
    let index = _.findIndex(itemData, (i) => {
      return i.item_id === val.item_id
    })
    let checkoutIndex = _.findIndex(checkoutItems, (i) => {
      return i.item_id === val.item_id
    })
    let temp = _.cloneDeep(itemData)
    let temp2 = _.cloneDeep(checkoutItems)
    temp[index] = val
    setItemData(temp)
    props.updateData("cart", {
      data: _.filter(temp, (e) => {
        return e.quantity > 0
      }),
    })
    props.updateCart(val)
    if (checkoutIndex > -1) {
      temp2[checkoutIndex] = val
      setCheckoutItems(temp2)
    }
  }

  const onDelete = (val) => {
    val.quantity = 0
    let index = _.findIndex(itemData, (i) => {
      return i.item_id === val.item_id
    })
    let checkoutIndex = _.findIndex(checkoutItems, (i) => {
      return i.item_id === val.item_id
    })
    let temp = _.cloneDeep(itemData)
    let temp2 = _.cloneDeep(checkoutItems)
    temp[index] = val
    setItemData(temp)
    props.updateData("cart", {
      data: _.filter(temp, (e) => {
        return e.quantity > 0
      }),
    })
    props.updateCart(val)
    if (checkoutIndex > -1) {
      temp2[checkoutIndex] = val
      setCheckoutItems(temp2)
    }
  }

  const swipeRight = (val) => {
    return (
      <Box>
        <Button
          color="#fff"
          width="60px"
          height="100%"
          borderRadius="0"
          colorScheme="danger"
          onPress={() => onDelete(val)}
        >
          <Icon
            as={<Ionicons name="trash-outline" />}
            color="white"
            size="md"
          />
        </Button>
      </Box>
    )
  }

  return (
    <VStack bg={ 'black' } height={ '100%' }>
      <VStack margin={2} padding={2} backgroundColor={"black"}>
        <HStack alignItems="center" width="100%">
          <Text bold color="white" fontSize={14} width="50%">
            Delivery Address
          </Text>
          <HStack display="flex" width="50%" justifyContent="flex-end">
            {editAddress ? (
              <IconButton
                icon={
                  <Icon
                    as={<AntDesign name="check" />}
                    color="white"
                    size="xs"
                  />
                }
                onPress={() => setEditAddress(false)}
              />
            ) : (
              <IconButton
                icon={
                  <Icon
                    as={<AntDesign name="edit" />}
                    color="white"
                    size="xs"
                    float="right"
                  />
                }
                onPress={() => setEditAddress(true)}
              />
            )}
          </HStack>
        </HStack>
        {editAddress ? (
          <TextArea
            shadow={2}
            h={20}
            placeholder="Text Area Placeholder"
            w="100%"
            value={deliverAddress}
            color="white"
            textAlignVertical="top"
            onChangeText={(message) => {
              setDeliverAddress(message)
            }}
            _light={{ placeholderTextColor: "trueGray.700" }}
            _dark={{ bg: "coolGray.800" }}
            _hover={{ bg: "coolGray.200" }}
          />
        ) : (
          <Text color="white" fontSize={12}>
            {deliverAddress}
          </Text>
        )}
      </VStack>
      <HStack pl={3} pt={2} space={2}>
        <Checkbox mr={1} onChange={(val) => checkAll(val)} />
        <Text bold color="white" textTransform="uppercase">
          Select All
        </Text>
      </HStack>
      {itemData.length > 0 ? (
        <FlatList
          data={itemData}
          px={3}
          refreshControl={ <RefreshControl refreshing={ false } onRefresh={ onRefresh } /> }
          height={editAddress ? "50%" : "60%"}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) =>
            item.quantity > 0 ? (
              <Swipeable renderRightActions={() => swipeRight(item)}>
                <HStack bg="#000" alignItems="center" mt={2}>
                  {
                   ( !item.is_sold_out && Number(item.quantity) <= Number(item.stock) ) && (
                     <Checkbox
                       mr={1}
                       isChecked={_.find(checkoutItems, { item_id: item.item_id })}
                       onChange={(val) => onCheck(val, item)}
                     />
                   )
                  }
                  <Box px={2}>
                    <Image
                      size={100}
                      bg="#BEBEBE"
                      alt="Product Image"
                      source={{
                        uri: item.src
                          ? item.src
                          : "https://icons-for-free.com/download-icon-mountains+photo+photos+placeholder+sun+icon-1320165661388177228_512.png",
                      }}
                    />
                  </Box>
                  <Box flex={1} pr={2}>
                    <VStack space={1}>
                      <Text color="white" fontSize="18px" bold>
                        {item.size
                          ? item.name + " (" + item.size + ")"
                          : item.product}
                      </Text>
                      <Text color="white">
                        {"Unit Price: RM " + Number(item.dist_price).toFixed(2)}
                      </Text>
                      {
                        item.is_sold_out && (
                          <HStack alignItems="center" space={5}>
                            <Text color='red.500' fontSize="lg">Sold Out</Text>
                          </HStack>
                        )
                      }
                      {
                        !item.is_sold_out && (
                          <>
                            {
                              Number(item.quantity) > Number(item.stock) && <Text color='red.500' fontSize="sm">Limited Stock. (Left { item.stock })</Text>
                            }
                            <HStack alignItems="center" space={5}>
                              <HStack h={8}>
                                <Button
                                  colorScheme="red"
                                  onPress={() => onDecrement(item)}
                                >
                                  -
                                </Button>
                                <Input
                                  bg="white"
                                  w={ 50 }
                                  mx={ 1 }
                                  isReadOnly
                                  textAlign="center"
                                  value={ item.quantity.toString()}
                                />
                                <Button
                                  colorScheme="red"
                                  onPress={() => onIncrement(item)}
                                >
                                  +
                                </Button>
                              </HStack>
                              <Text color="white" fontSize="lg">
                                {"RM " +
                                  (Number(item.dist_price) * item.quantity)
                                    .toFixed(2)
                                    .toString()}
                              </Text>
                            </HStack>
                          </>
                            
                        )
                      }
                    </VStack>
                  </Box>
                </HStack>
              </Swipeable>
            ) : (
              <></>
            )
          }
        />
      ) : (
        <Text
          bold
          color="white"
          textTransform="uppercase"
          my={20}
          fontSize={20}
          textAlign="center"
        >
          No item in cart
        </Text>
      )}
      <HStack justifyContent="flex-end" px={5} alignItems="center">
        <Text color="white" fontSize="xl">
          {"Total:  "}
        </Text>
        <Text color="white" fontSize="3xl">
          {"RM " + subtotal}
        </Text>
      </HStack>
      <Button
        mx={3}
        colorScheme="red"
        py={4}
        disabled={checkoutItems.length===0}
        onPress={() => {
          navigation.navigate("checkout", {
            checkoutItems: checkoutItems,
            subtotal: subtotal,
            deliverAddress: deliverAddress,
          })
        }}
      >
        <Text color="white" fontSize="lg">
          Checkout
        </Text>
      </Button>
    </VStack>
  )
}

export default CartHOC(Cart)
