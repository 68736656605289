import React, { useEffect, createContext } from "react"
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs"
import Statistic from "./Statistic"
import DownlineList from "./DownlineList"
import DownlineHOC from "./action"

const Tab = createMaterialTopTabNavigator()
const DownlineContext = createContext()
const Downlines = (props) => {
  useEffect(() => {
    props.getDownline()
  }, [])
  return (
    <DownlineContext.Provider value={props.reduxData.downlines}>
      <Tab.Navigator
        screenOptions={{
          tabBarLabelStyle: { fontSize: 12 },
          tabBarStyle: { backgroundColor: "#222222" },
          tabBarActiveTintColor: "white",
          tabBarInactiveTintColor: "white",
        }}
      >
        <Tab.Screen
          name="statistic"
          component={Statistic}
          initialParams={{ downlines: props.reduxData.downlines, DownlineContext: DownlineContext }}
        />
        <Tab.Screen
          name="list"
          component={DownlineList}
          initialParams={{ downlines: props.reduxData.downlines, DownlineContext: DownlineContext }}
        />
      </Tab.Navigator>
    </DownlineContext.Provider>
  )
}

export default DownlineHOC(Downlines)
