import React, { useEffect, useState } from "react"
import LoadingModal from "../../../components/LoadingModal"
import { FontAwesome, Entypo } from "@expo/vector-icons"
import { Center, Button, Box, Text, HStack, VStack } from "native-base"

const PostPayment = (props) => {
  let paymentInfo = props.route.params.queryParams
  const [ loading, setLoading ] = useState( true )

  useEffect( () => {
    if( paymentInfo["billplz[paid]"] === 'true' ){
      setLoading( true )
      setTimeout(() => {
        setLoading( false )
      }, 3000 );
    } else {
      setLoading( false )
    }
  }, [ props ])

  return (
    <VStack bg={ 'black' } height={ '100%' }>
      <Center flex={1}>
        {
          !loading && (
            <Box bg="rgba(255,255,255,0.2)" p="5" borderRadius="20">
              <Center mb="20px">
                {paymentInfo["billplz[paid]"] === "true" ? (
                  <FontAwesome name="check-circle" size={50} color="#22C55E" />
                ) : (
                  <Entypo name="circle-with-cross" size={50} color="#ef4444" />
                )}
                <Text color="#aaa" fontSize="12px">
                  Bill ID: {paymentInfo["billplz[id]"]}
                </Text>
                <Text color="#ddd" fontSize="18px" textAlign="center">
                  {paymentInfo["billplz[paid]"] === "true"
                    ? "Payment is successful. Please allow 5-7 working days for your order to be delivered"
                    : "The payment is unsuccessful and charges is not made. Please try again."}
                </Text>
              </Center>
              <Button
                colorScheme="dark"
                onPress={() => {
                 window.location = window.location.origin
                }}
              >
                <HStack alignItems="center" space={2}>
                  <Entypo name="home" size={24} color="#222" />
                  <Text color="#222">Back to Home</Text>
                </HStack>
              </Button>
            </Box>
          )
        }
        { loading && <LoadingModal message={ 'Processing your order' }/> }
      </Center>
    </VStack>
  )
}

export default PostPayment;
