import React, { useState } from "react"
import { MaterialIcons } from "@expo/vector-icons"
import {
  Text,
  Center,
  VStack,
  Button,
  Input,
  FormControl,
  Icon,
  useToast,
} from "native-base"
import _ from 'lodash'
import LoadingModal from "../../components/LoadingModal"
const logo = require("../../assets/logo.png")

const NewPassword = (props) => {
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState({
    emailCode: "",
    password1: "",
    password2: ""
  })
  const [error, setError] = useState({})
  const dataValidation = () => {
    let tmpError = {}
    Object.keys(info).map( key => {
      if(info[key]=== ""){
        tmpError[key] = "Sila memastikan kotak ini telah diisikan"
      }
    })
    setError(tmpError)
    if(_.isEmpty(tmpError)){
      props.navigation.navigate("login")
    }
  }

  return (
    <Center flex={1}>
      <VStack
        space={3}
        width="90%"
        bg="rgba(255,255,255,0.2)"
        p={5}
        borderRadius={20}
      >
        <Text bold fontSize="lg" color="white">
          Kata Laluan Baharu
        </Text>
        <Text color="#fff">
          Sila masukkan kod yang diberikan pada emel anda dan memasukkan kata laluan yang baharu.
        </Text>
        <FormControl isInvalid={"emailCode" in error}>
          <Input
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="mail" />}
                size="md"
                m={2}
                _light={{
                  color: "gray.500",
                }}
                _dark={{
                  color: "gray.500",
                }}
              />
            }
            variant="filled"
            placeholder="Kod dalam emel"
            onChangeText={(value) => setInfo({...info, emailCode: value})}
          />
          <FormControl.ErrorMessage
            mb={1}
            mt={0}
            _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
          >
            {error.emailCode}
          </FormControl.ErrorMessage>
        </FormControl>
        <FormControl isInvalid={"password1" in error}>
          <Input
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="lock" />}
                size="md"
                m={2}
                _light={{
                  color: "gray.500",
                }}
                _dark={{
                  color: "gray.500",
                }}
              />
            }
            variant="filled"
            placeholder="Kata Laluan Baharu"
            onChangeText={(value) => setInfo({...info, password1: value})}
          />
          <FormControl.ErrorMessage
            mb={1}
            mt={0}
            _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
          >
            {error.password1}
          </FormControl.ErrorMessage>
        </FormControl>
        <FormControl isInvalid={"password2" in error}>
          <Input
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="lock" />}
                size="md"
                m={2}
                _light={{
                  color: "gray.500",
                }}
                _dark={{
                  color: "gray.500",
                }}
              />
            }
            variant="filled"
            placeholder="Memastikan kata laluan baharu"
            onChangeText={(value) => setInfo({...info, password2: value})}
          />
          <FormControl.ErrorMessage
            mb={1}
            mt={0}
            _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
          >
            {error.password2}
          </FormControl.ErrorMessage>
        </FormControl>
        <Button
          colorScheme="teal"
          p={4}
          onPress={() => {
            dataValidation()
          }}
        >
          Hantar
        </Button>
      </VStack>
      {loading && <LoadingModal />}
    </Center>
  )
}

export default NewPassword
