import React, { useState, useEffect } from "react"
import {
  HStack,
  VStack,
  Image,
  Text,
  IconButton,
  Icon,
  TextArea,
  FlatList,
  Button,
  Box,
  Input,
  ScrollView,
} from "native-base"
import { Platform } from "react-native"
import { MaterialCommunityIcons, Feather, AntDesign, Entypo } from "@expo/vector-icons"
import _ from "lodash"

import CheckoutHOC from "./actions"

const Checkout = (props) => {
  const [editAddress, setEditAddress] = useState(false)
  const [deliverAddress, setDeliverAddress] = useState("")
  const [subtotal, setSubTotal] = useState(0)
  const [voucherCode, setVoucherCode] = useState("")

  useEffect(() => {
    setDeliverAddress(props.route.params.deliverAddress)
    setSubTotal(
      _.sumBy(props.route.params.checkoutItems, (o) => {
        return o.dist_price * o.quantity
      })
    )
  }, [])

  return (
    <ScrollView flex={1} bgColor={ 'black' } height={ '100%' }>
      <VStack flex={1}>
        <VStack m={2} mb={0} p={2} backgroundColor={"black"}>
          <HStack alignItems="center" width="100%">
            <Text bold color="white" fontSize={14} width="50%">
              Delivery Address
            </Text>
            <HStack display="flex" width="50%" justifyContent="flex-end">
              {editAddress ? (
                <IconButton
                  icon={
                    <Icon
                      as={<AntDesign name="check" />}
                      color="white"
                      size="xs"
                    />
                  }
                  onPress={() => setEditAddress(false)}
                />
              ) : (
                <IconButton
                  icon={
                    <Icon
                      as={<AntDesign name="edit" />}
                      color="white"
                      size="xs"
                      float="right"
                    />
                  }
                  onPress={() => setEditAddress(true)}
                />
              )}
            </HStack>
          </HStack>
          {editAddress ? (
            <TextArea
              shadow={2}
              h={20}
              placeholder="Text Area Placeholder"
              w="100%"
              value={deliverAddress}
              color="white"
              textAlignVertical="top"
              onChangeText={(message) => {
                setDeliverAddress(message)
              }}
              _light={{ placeholderTextColor: "trueGray.700" }}
              _dark={{ bg: "coolGray.800" }}
              _hover={{ bg: "coolGray.200" }}
            />
          ) : (
            <HStack>
              <MaterialCommunityIcons
                name="truck-fast-outline"
                size={24}
                color="white"
              />
              <Text color="white" fontSize={12} ml={3} pr={20}>
                {deliverAddress}
              </Text>
            </HStack>
          )}
        </VStack>
        <VStack flex={1} mx={2} my={0} p={2} pt={0} height={editAddress ? "50%" : "55%"}>
          <Text bold color="white" fontSize={14} width="50%" py={3}>
            Checkout Item
          </Text>
          <FlatList
            data={props.route.params.checkoutItems}
            px={3}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (
              <HStack alignItems="center">
                <Box py={2}>
                  <Image
                    size={90}
                    mr={3}
                    bg="#BEBEBE"
                    alt="Product Image"
                    source={{
                      uri: item.src
                        ? item.src
                        : "https://icons-for-free.com/download-icon-mountains+photo+photos+placeholder+sun+icon-1320165661388177228_512.png",
                    }}
                  />
                </Box>
                <Box flex={1} pr={2}>
                  <VStack space={1}>
                    <Text color="white" fontSize="18px" bold>
                      {item.size
                        ? item.name + " (" + item.size + ")"
                        : item.product}
                    </Text>
                    <Text color="white">
                      {"Unit Price: RM " + Number(item.dist_price).toFixed(2)}
                    </Text>
                    <HStack alignItems="center" space={5}>
                      <HStack h={8}>
                        <Input
                          bg="white"
                          isReadOnly
                          w={ 50 }
                          textAlign="center"
                          value={item.quantity.toString()}
                        />
                      </HStack>
                      <Text color="white" fontSize="lg">
                        {"RM " +
                          (Number(item.dist_price) * item.quantity)
                            .toFixed(2)
                            .toString()}
                      </Text>
                    </HStack>
                  </VStack>
                </Box>
              </HStack>
            )}
          />
        </VStack>
        <VStack flex={1}>
          <HStack>
            <Input
              isDisabled={props.discountAmount > 0}
              flex={1}
              mx={2}
              variant="outline"
              placeholderTextColor="#ff00ff"
              color="#ffffff"
              placeholder="Have a voucher code? Key in here"
              value={voucherCode}
              onChangeText={(value) => {
                setVoucherCode(value)
              }}
              _disabled={{color:'#222'}}
              InputRightElement={
                <IconButton
                  icon={
                    <Icon
                      as={props.discountAmount === 0 ? <Feather name="arrow-right-circle" /> : <Entypo name="circle-with-cross"/>}
                      size={7}
                      color="muted.400"
                    />
                  }
                  onPress={() => {
                    if (props.discountAmount === 0) {
                      props.claimVoucher(
                        {
                          code: voucherCode.toUpperCase(),
                          price_before: subtotal,
                        },
                        setSubTotal
                      )
                      setVoucherCode(voucherCode.toUpperCase())
                    } else {
                      setVoucherCode("")
                      setSubTotal(subtotal + props.discountAmount)
                      props.onChangeCheckoutHOC(0, 'discountAmount')
                    }
                  }}
                />
              }
            />
          </HStack>
          {(!_.isEmpty(props.claimError) || props.discountAmount > 0) && (
            <Text
              color={props.claimError ? "#f00" : "#fff"}
              px={2}
              pr={5}
              w="100%"
              textAlign={props.claimError ? "left" : "right"}
            >
              {props.claimError
                ? props.claimError
                : `- RM ${props.discountAmount.toFixed(2)}`}
            </Text>
          )}
          <HStack justifyContent="flex-end" px={5} alignItems="center">
            <Text color="white" fontSize="lg">
              {"Total:  "}
            </Text>
            <Text color="white" fontSize="28px">
              {"RM " + Number(subtotal).toFixed(2).toString()}
            </Text>
          </HStack>
          <Button
            mx={3}
            colorScheme="red"
            py={2}
            height="50px"
            onPress={() => props.createBill(
                props.route.params.checkoutItems,
                subtotal,
                deliverAddress,
                props.route.params.orderId
              )}
          >
            <Text color="white" fontSize="lg">
              Pay
            </Text>
          </Button>
        </VStack>
      </VStack>
    </ScrollView>
  )
}

export default CheckoutHOC(Checkout)
