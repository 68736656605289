import {
  Button,
  HStack,
  Text,
  VStack,
  Center,
  Box,
  Input,
  TextArea,
} from "native-base"
import React, { useEffect, useState } from "react"
import PasswordHOC from "./action"
import _ from "lodash"

const ChangePassword = (props) => {
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
  })
  const [err, setErr] = useState("")
  const onSubmit = () => {
    if (password.newPassword1.length > 7) {
      if (password.newPassword1.length < 21) {
        if(password.newPassword1 !== password.oldPassword){
          if (password.newPassword1 === password.newPassword2) {
            props.updatePassword(password.newPassword1)
          } else {
            setErr("New password is not match with the confirmation password")
          }
        } else {
          setErr("New password is the same as old password")
        }
      } else {
        setErr("New password should not have more than 20 characters")
      }
    } else {
      setErr("New password should have at least 8 characters")
    }
  }
  return (
    <VStack bg={ 'black' } height={ '100%' }>
      <Center flex={1}>
        <Box bg="#555" w="80%" mb="10px" borderRadius="20px" p={3}>
          <VStack space={2}>
            <HStack alignItems="center">
              <Text color="#fff" flex={1}>
                New Password
              </Text>
              <VStack flex={2}>
                <Input
                  variant="filled"
                  placeholder="New Password"
                  value={password.newPassword1}
                  type="password"
                  onChangeText={(value) =>
                    setPassword({ ...password, newPassword1: value })
                  }
                />
              </VStack>
            </HStack>
            <HStack alignItems="center">
              <Text color="#fff" flex={1}>
                Confirm New Password:
              </Text>
              <VStack flex={2}>
                <Input
                  variant="filled"
                  placeholder="Confirm Password"
                  value={password.whatsapp}
                  type="password"
                  onChangeText={(value) =>
                    setPassword({ ...password, newPassword2: value })
                  }
                />
              </VStack>
            </HStack>
          </VStack>
          {err && (
            <Text color="#ff0000" fontSize="12px">
              {err}
            </Text>
          )}
        </Box>
        <Button w="80%" colorScheme="success" onPress={() => onSubmit()}>
          Submit
        </Button>
      </Center>
    </VStack>
  )
}

export default PasswordHOC(ChangePassword)
