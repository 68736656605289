import React, { Component } from 'react'

import { connect } from "react-redux"
import { clearData } from '../../../action'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
          />
        </>
      )
    }
  }

  const mapStateToProps = (state) => ({
    reduxData: state,
  })

  return connect(mapStateToProps, { clearData })(WithHOC)
}


export default HOC