let initialState = {
  uid: "",
  user: {},
  cart: {},
  product: {},
  menuTitle: "home"
}

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_DATA":
      return {
        ...state,
        [action.target]: action.payload,
      }
    case "CLEAR_DATA":
      return {
        initialState,
      }
    default:
      return state
  }
}

export default dataReducer