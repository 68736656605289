import {
  Button,
  HStack,
  Text,
  VStack,
  Center,
  Box,
  Input,
  TextArea,
} from "native-base"
import React, { useEffect, useState } from "react"
import ProfileHOC from "./action"
import _ from "lodash"

const EditProfile = (props) => {
  const [profileInfo, updateProfileInfo] = useState({})
  const [err, setErr] = useState({})
  useEffect(() => {
    updateProfileInfo(props.reduxData.user)
  }, [])

  const onSubmit = () => {
    let checkKeys = ["username", "address", "worker_id", "whatsapp"]
    let tmpErr = {}
    checkKeys.map((key) => {
      if (profileInfo[key] === "") {
        tmpErr = { ...tmpErr, [key]: "*This field is required!" }
      }
    })
    setErr(tmpErr)
    if (_.isEmpty(tmpErr)) {
      props.updateUserProfile({...profileInfo, uid:props.reduxData.uid})
    }
  }
  return (
    <VStack bg={ 'black' } height={ '100%' }>
      <Center flex={1}>
        <Box bg="#555" w="80%" mb="10px" borderRadius="20px" p={3}>
          <VStack space={2}>
            <HStack alignItems="center">
              <Text color="#fff" flex={1}>
                Username:
              </Text>
              <VStack flex={2}>
                <Input
                  variant="filled"
                  placeholder="Username"
                  value={profileInfo.username}
                  onChangeText={(value) =>
                    updateProfileInfo({ ...profileInfo, username: value })
                  }
                />
                {err.username && (
                  <Text color="#ff0000" fontSize="12px">
                    {err.username}
                  </Text>
                )}
              </VStack>
            </HStack>
            <HStack alignItems="center">
              <Text color="#fff" flex={1}>
                Address:
              </Text>
              <VStack flex={2}>
                <TextArea
                  variant="filled"
                  placeholder="Address"
                  value={profileInfo.address}
                  onChangeText={(value) =>
                    updateProfileInfo({ ...profileInfo, address: value })
                  }
                />
                {err.address && (
                  <Text color="#ff0000" fontSize="12px">
                    {err.address}
                  </Text>
                )}
              </VStack>
            </HStack>
            <HStack alignItems="center">
              <Text color="#fff" flex={1}>
                Worker ID:
              </Text>
              <VStack flex={2}>
                <Input
                  variant="filled"
                  placeholder="Worker ID"
                  value={profileInfo.worker_id}
                  onChangeText={(value) =>
                    updateProfileInfo({ ...profileInfo, worker_id: value })
                  }
                />
                {err.worker_id && (
                  <Text color="#ff0000" fontSize="12px">
                    {err.worker_id}
                  </Text>
                )}
              </VStack>
            </HStack>
            <HStack alignItems="center">
              <Text color="#fff" flex={1}>
                Whatsapp:
              </Text>
              <VStack flex={2}>
                <Input
                  variant="filled"
                  placeholder="Whatsapp"
                  value={profileInfo.whatsapp}
                  onChangeText={(value) =>
                    updateProfileInfo({ ...profileInfo, whatsapp: value })
                  }
                />
                {err.whatsapp && (
                  <Text color="#ff0000" fontSize="12px">
                    {err.whatsapp}
                  </Text>
                )}
              </VStack>
            </HStack>
          </VStack>
        </Box>
        <Button w="80%" colorScheme="success" onPress={() => onSubmit()}>Submit</Button>
      </Center>
    </VStack>
  )
}

export default ProfileHOC(EditProfile)
