import React, { Component } from "react"

import { Get } from "../../../utils/axios"
import { connect } from "react-redux"
import { updateData } from "../../../action"

import LoadingModal from '../../../components/LoadingModal'
import _ from "lodash"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      banners: []
    }

    load = (param) => this.setState({ loading: param })

    getMobileBanners = () => { 
      Get(
        `/getMobileBanner`,
        this.getMobileBannersSuccess,
        this.getMobileBannersError,
        this.load
      )
    }

    getMobileBannersSuccess = (payload) => {
      this.setState({banners: payload.data})
    }
    getMobileBannersError = (err) => console.log(err)

    getDownline = () => { 
      Get(
        `/getDownline?upline_id=${this.props.reduxData.uid}`,
        this.getDownlineSuccess,
        this.getDownlineError,
        this.load
      )
    }

    getDownlineSuccess = (payload) => {
      // console.log(payload.data.downline)
      this.props.updateData('downlines', payload.data.downline )
    }
    getDownlineError = (err) => console.log(err)

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            banners={this.state.banners}
            downlines={ this.state.downlines}
            getMobileBanners={this.getMobileBanners}
            getDownline={ this.getDownline }
          />
          {this.state.loading && <LoadingModal />}
        </>
      )
    }
  }

  const mapStateToProps = (state) => ({
    reduxData: state,
  })

  return connect(mapStateToProps, { updateData })(WithHOC)
}

export default HOC
