import React, { useEffect, useState } from "react"
import {
  Text,
  Center,
  SectionList,
  Heading,
  Image,
  HStack,
  VStack,
  View,
  Box,
} from "native-base"
import { TouchableOpacity, RefreshControl } from "react-native"
import Material from "./material"
import MaterialHOC from "../actions"

const categorizeData = (data, category) => {
  let tmpData = []
  data.map((item, index) => {
    let tmpIndex = tmpData.map((e) => e.title).indexOf(item[category])
    let tmpObj = {}
    if (tmpIndex > -1) tmpData[tmpIndex].data.push(item)
    else {
      tmpObj = { title: item[category], data: [] }
      tmpObj.data.push(item)
      tmpData.push(tmpObj)
    }
  })

  return tmpData
}

const Materials = (props) => {
  const [data, setData] = useState([])
  const [isModalOpen, setShowModal] = useState(false)
  const [selectedItem, selectItem] = useState({})

  useEffect(() => {
    if(props.reduxData.material){
      setData(categorizeData( props.reduxData.material, "category"))
    }
  }, [ props.reduxData.material ])

  const onRefresh = () => {
    props.getMaterial()
  }

  return (
    <VStack bgColor={ 'black' } height={ '100%' }>
      <Material
        isOpen={isModalOpen}
        setShowModal={setShowModal}
        item={selectedItem}
        addToCart={ props.addToCart }
      />
      <SectionList
        sections={data}
        refreshControl={ <RefreshControl refreshing={ false } onRefresh={ onRefresh } /> }
        keyExtractor={(item, index) => item + index}
        renderItem={({ item }) => (
          <TouchableOpacity
            onPress={() => {
              setShowModal(true)
              selectItem(item)
            }}
          >
            <HStack>
              <View px={2} py={2}>
                <Image
                  size={115}
                  bg="#BEBEBE"
                  alt="Material Image"
                  source={{
                    uri: item.image_url.length > 0 ? item.image_url[0] : "https://icons-for-free.com/download-icon-mountains+photo+photos+placeholder+sun+icon-1320165661388177228_512.png",
                  }}
                />
              </View>
              <Box flex={1} pt={3} pr={2}>
                <VStack>
                  <Text color="white" fontSize="18px" bold pb={1}>
                    {item.material_volume ? item.material_name + " (" + item.material_volume + ")":item.material_name}
                  </Text>
                  <Text color="white" fontSize="14px">
                    {item.material_description}
                  </Text>
                  <Text color="white" fontSize="14px">
                    Stocks: {item.stock}
                  </Text>
                </VStack>
              </Box>
            </HStack>
          </TouchableOpacity>
        )}
        renderSectionHeader={({ section: { title } }) => (
          <Heading fontSize="xl" mt={6} pb={4} px={2} color="white">
            {title}
          </Heading>
        )}
      />
    </VStack>
  )
}

export default MaterialHOC( Materials );
