import {
  VStack,
  Box,
  Text,
  Center,
  HStack,
  ScrollView,
  Button,
} from "native-base"
import { PieChart, LineChart } from "react-native-chart-kit"
import { Dimensions } from "react-native"
import React, { useState, useEffect, useContext } from "react"
import _ from "lodash"

const upperInfoColor = "#FFF"

const chartConfig = {
  backgroundColor: "#e26a00",
  backgroundGradientFrom: "#fb8c00",
  backgroundGradientTo: "#ffa726",
  decimalPlaces: 2,
  color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
}

const pieDataPrep = (data, accessor) => {
  let tmp = _.orderBy(
    data,
    (e) => {
      return e.sales[accessor]
    },
    ["desc"]
  )
  let processedData = []
  let colorSet = ["#27612a", "#388e3c", "#8bc34a", "#d5e356", "#fff59d"]
  tmp.map((item, index) => {
    if (index < 4) {
      tmp[index] = {
        ...item,
        total_sales: item.sales[accessor],
        color: colorSet[index],
        legendFontColor: "#FFF",
        legendFontSize: "15",
      }
      processedData.push(tmp[index])
    } else {
      let tmpData = {
        name: "Other",
        total_sales:
          index === 4
            ? item.sales[accessor]
            : processedData[4].total_sales + item.sales[accessor],
        color: colorSet[4],
        legendFontColor: "#FFF",
        legendFontSize: "15",
      }
      processedData[4] = tmpData
    }
  })
  return processedData
}

const Statistic = (props) => {
  const [pieData, setPieData] = useState([])
  const [overallSales, setOverallSales] = useState(0)
  
  let downlines = useContext(props.route.params.DownlineContext)
  useEffect(() => {
    setPieData(pieDataPrep(downlines, "month"))
    setOverallSales(
      _.sumBy(downlines, (e) => {
        return e.sales["month"]
      })
    )
  }, [downlines])
  return (
    <ScrollView bgColor={ 'black' }>
      <VStack space={5}>
        <Box
          mt={5}
          flex={1}
          m={2}
          borderRadius={20}
          alignItems="center"
          justifyContent="center"
        >
          <Center zIndex="-1" width="100%" height="100%" position="absolute">
            <Box
              width="100%"
              height="100%"
              borderRadius={20}
              bg="white"
              opacity={0.1}
            />
          </Center>
          <Center pb={2}>
            <Box width="100%">
              <VStack>
                <Text
                  pt={3}
                  fontSize="xl"
                  textAlign="center"
                  color={upperInfoColor}
                  bold
                >
                  Total Sales
                </Text>
                <Text textAlign="center" color={upperInfoColor}>
                  By Downlines
                </Text>
                <Text textAlign="center" color={upperInfoColor} fontSize="4xl">
                  RM {overallSales.toFixed(2)}
                </Text>
              </VStack>
            </Box>
          </Center>
          <Center width="75%" opacity={0.5}>
            <Box
              borderColor="white"
              borderWidth={1}
              width="100%"
              mb={4}
              shadow={5}
            />
          </Center>
          <VStack flex={1}>
            <Text fontSize="sm" textAlign="center" color={upperInfoColor} bold>
              Best Product Sold
            </Text>
            <Text
              mb={5}
              fontSize="xl"
              textAlign="center"
              color={upperInfoColor}
            >
              Date & Banana Milk
            </Text>
          </VStack>
          <VStack flex={1}>
            <Text fontSize="sm" textAlign="center" color={upperInfoColor} bold>
              Best Seller
            </Text>
            <Text
              mb={5}
              fontSize="xl"
              textAlign="center"
              color={upperInfoColor}
            >
              {pieData.length > 0 ? pieData[0].username : "No Record"}
            </Text>
          </VStack>
        </Box>
        {/* <Center>
          <Button
            _text={{ fontSize: "md" }}
            borderRadius={20}
            colorScheme="teal"
          >
            Generate Group Sales Report
          </Button>
        </Center>
        <Center>
          <VStack>
            <Text fontSize="xl" color="white" pl={3} bold textAlign="center">
              Total Sales
            </Text>
            <Text fontSize="sm" color="white" pl={3} bold textAlign="center">
              By Direct Downlines
            </Text>
          </VStack>
          <LineChart
            data={{
              labels: ["9/6", "9/13", "9/20", "9/27", "10/4", "10/11"],
              datasets: [
                {
                  data: [
                    Math.random() * 100,
                    Math.random() * 100,
                    Math.random() * 100,
                    Math.random() * 100,
                    Math.random() * 100,
                    Math.random() * 100,
                  ],
                },
              ],
            }}
            width={Dimensions.get("window").width * 0.9} // from react-native
            height={220}
            yAxisLabel="$"
            yAxisSuffix="k"
            yAxisInterval={1} // optional, defaults to 1
            chartConfig={{
              backgroundColor: "#E26A00",
              backgroundGradientFrom: "#ff4f8a",
              backgroundGradientTo: "#ffe84f",
              decimalPlaces: 2,
              color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
              labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
              style: {
                borderRadius: 16,
              },
              propsForDots: {
                r: "6",
                strokeWidth: "2",
                stroke: "#ffa726",
              },
            }}
            bezier
            style={{
              marginVertical: 8,
              borderRadius: 16,
            }}
          />
        </Center>
        <Center>
          <VStack>
            <Text fontSize="xl" color="white" pl={3} bold textAlign="center">
              Sales Percentage
            </Text>
            <Text fontSize="sm" color="white" pl={3} bold textAlign="center">
              By Direct Downlines
            </Text>
          </VStack>
          <Box
            bg={{
              linearGradient: {
                colors: ["#ffe84f", "#4fffe8"],
                start: [0, 0.5],
                end: [1, 0],
              },
            }}
            mb={5}
            rounded="xl"
            width="90%"
          >
            {pieData.length > 0 && pieData[0].total_sales !== 0 ? (
              <>
                <Center>
                  <PieChart
                    data={pieData}
                    width={200}
                    height={200}
                    chartConfig={chartConfig}
                    accessor={"total_sales"}
                    center={[50, 0]}
                    hasLegend={false}
                  />
                </Center>
                <Center pb={2}>
                  <VStack space={2}>
                    {pieData.map((item, index) => {
                      return (
                        <HStack space={1}>
                          <Box
                            bg={item.color}
                            width={5}
                            height={5}
                            borderRadius={10}
                          />
                          <Text pl={2} width="60px">
                            {((item.total_sales / overallSales) * 100).toFixed(
                              2
                            )}
                            %
                          </Text>
                          <Text>{item.name}</Text>
                        </HStack>
                      )
                    })}
                  </VStack>
                </Center>
              </>
            ) : (
              <Center height={200}>
                <Text>No Record</Text>
              </Center>
            )}
          </Box>
        </Center> */}
      </VStack>
    </ScrollView>
  )
}
export default Statistic
