import { Box, HStack, Icon, IconButton, Text } from "native-base"
import React, { useState, useEffect, Component } from "react"
import { connect } from "react-redux"
import { MaterialIcons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"
import { updateData } from "../../action"

import { Badge } from "react-native-paper"
import _ from "lodash"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    render = () => {
      return (
        <>
          <WrappedComponent {...this.props} />
        </>
      )
    }
  }

  const mapStateToProps = (state) => ({
    reduxData: state,
  })

  return connect(mapStateToProps, { updateData })(WithHOC)
}

const TopMenu = (props) => {
  const navigation = useNavigation()
  const [prevTitle, setPrevTitle] = useState("")

  return (
    <Box bg="black" width="100%">
      <HStack alignItems="center" width="100%">
        <Text
          color="white"
          width="50%"
          px={8}
          fontSize="xl"
          bold
          textTransform="uppercase"
        >
          {props.reduxData.menuTitle ? props.reduxData.menuTitle : ""}
        </Text>
        <HStack
          display="flex"
          width="50%"
          justifyContent="flex-end"
          padding="10px 20px"
        >
          {props.reduxData.cart && props.reduxData.cart.data && props.reduxData.cart.data.length > 0 && (
            <Badge
              style={{ position: "absolute", right: 45, top: 10, zIndex: 1 }}
            >
              {props.reduxData.cart.data.length}
            </Badge>
          )}
          <IconButton
            icon={
              <Icon
                as={<MaterialIcons name="shopping-cart" />}
                color="white"
                size="sm"
              />
            }
            onPress={() => {
              if (
                navigation.getState().routes[0].state &&
                navigation.getState().routes[0].state.routes.length > 0 &&
                navigation.getState().routes[0].state.routes[
                  navigation.getState().routes[0].state.routes.length - 1
                ].name === "cart"
              ) {
                navigation
                  .getParent()
                  .getState()
                  .routes[1].params.navigationRef.current.goBack()
                props.updateData("menuTitle", prevTitle)
              } else {
                navigation.navigate("cart")
                if (props.reduxData.menuTitle !== "cart") {
                  setPrevTitle(props.reduxData.menuTitle)
                  props.updateData("menuTitle", "cart")
                }
              }
              console.log(
                navigation.getState().routes[0].state &&
                  navigation.getState().routes[0].state.routes.length > 0 &&
                  navigation.getState().routes[0].state.routes[
                    navigation.getState().routes[0].state.routes.length - 1
                  ].name === "cart"
              )
            }}
          />
          <IconButton
            icon={
              <Icon
                as={<MaterialIcons name="notifications" />}
                color="white"
                size="sm"
              />
            }
            onPress={() => navigation.toggleDrawer()}
          />
        </HStack>
      </HStack>
    </Box>
  )
}

export default HOC(TopMenu)
