import React, { Component } from "react"

import { Post } from "../../../utils/axios"
import { connect } from "react-redux"
import { updateData } from "../../../action"

import LoadingModal from '../../../components/LoadingModal'
import _ from "lodash"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false
    }

    load = (param) => this.setState({ loading: param })

    updatePassword = (newPassword) => { 
      Post(
        `/changeUserCredential`,
        {
          uid: this.props.reduxData.uid,
          email: this.props.reduxData.user.email,
          password: newPassword
        },
        (payload)=>this.updatePasswordSuccess(payload, newPassword),
        this.updatePasswordError,
        this.load
      )
    }

    updatePasswordSuccess = (payload, newPassword) => {
      let tmp = _.cloneDeep(this.props.reduxData.user)
      tmp.password = newPassword
      this.props.updateData('user', tmp )
      this.props.navigation.goBack()
    }
    updatePasswordError = (err) => console.log(err)

    updateEmail = (email) => { 
      Post(
        `/changeUserCredential`,
        {
          uid: this.props.reduxData.uid,
          email: email,
          password: this.props.reduxData.user.password
        },
        (payload)=>this.updateEmailSuccess(payload, email),
        this.updatePasswordError,
        this.load
      )
    }

    updateEmailSuccess = (payload, email) => {
      let tmp = _.cloneDeep(this.props.reduxData.user)
      tmp.email = email
      this.props.updateData('user', tmp )
      this.props.navigation.goBack()
    }
    updateEmailError = (err) => console.log(err)

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            updatePassword={this.updatePassword}
            updateEmail={this.updateEmail}
          />
          {this.state.loading && <LoadingModal />}
        </>
      )
    }
  }

  const mapStateToProps = (state) => ({
    reduxData: state,
  })

  return connect(mapStateToProps, { updateData })(WithHOC)
}

export default HOC
