import React, { Component } from "react"
import * as Linking from "expo-linking"
import { connect } from "react-redux"
import _ from "lodash"
import { updateData } from "../../../action"
import { Get, Post, PaymentPost } from "../../../utils/axios"
import LoadingModal from "../../../components/LoadingModal"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      cart: [],
      checkoutItems: [],
      subtotal: "",
      address: "",
      orderId: "",
      claimError: "",
      paymentID: "",
      discountAmount: 0,
    }

    load = (param) => this.setState({ loading: param })
    onChangeCheckoutHOC = (val, context) => this.setState({ [context]: val })

    createBill = (checkoutItems, subtotal, address, orderId) => {
      this.setState({
        checkoutItems: checkoutItems,
        subtotal: subtotal,
        address: address,
        orderId: orderId,
      }, () => {
        Post(
          `/orderPayment`,
          {
            orderBy: this.props.reduxData.uid,
            transactionDate: new Date().toISOString(),
            checkoutItems: checkoutItems || [],
            subtotal: subtotal || 0,
            address: address || '',
            orderId: orderId || '',
          },
          this.createBillSuccess,
          this.createBillError,
          this.load
        )
      })
    }
    createBillSuccess = payload => {
      this.setState({ paymentID: payload.data.payment_id }, () => {
        this.makePayment()
      })
    }
    createBillError = (error) => console.log(error.message)

    getCart = () =>
      Get(
        `/getCartItems?order_by=${this.props.reduxData.uid}`,
        this.getCartSuccess,
        this.getCartError,
        this.load
      )
    getCartSuccess = (payload) => {
      this.props.updateData("cart", payload.data)
    }
    getCartError = (error) => {
      console.log(error.message)
    }

    makePayment = () => {
      Post(
        `/pwaPayment`,
        {
          collection_id: "lare31k0", //"lare31k0","rolnuw5q",
          email: this.props.reduxData.user.email,
          name: this.props.reduxData.user.username,
          amount: this.state.subtotal * 100,
          mobile: this.props.reduxData.user.whatsapp,
          description: "Appetit Purchase Payment",
          callback_url: `https://asia-southeast1-distributor-system-bc214.cloudfunctions.net/storeBill?payment_id=${ this.state.paymentID }`,
          redirect_url: Linking.createURL("post-payment"),
        },
        this.makePaymentSuccess,
        this.makePaymentError,
        () => this.setState({ loading: true })
      )
    }
    makePaymentSuccess = (payload) => window.open( payload.data, '_self' )
    makePaymentError = (error) => console.log(error.message)

    claimVoucher = (data, setPrice, updateClaim) =>
      Post(
        `/claimVoucher`,
        data,
        (payload) => this.claimVoucherSuccess(payload, data, setPrice),
        this.claimVoucherError,
        this.load
      )
    claimVoucherSuccess = (payload, data, setPrice) => {
      let tmp = payload.data
      if(tmp.price_after){
        this.setState({discountAmount: data.price_before - parseInt(tmp.price_after)})
        setPrice(parseInt(tmp.price_after))
      } else if(tmp.message){
        this.setState({claimError: tmp.message})
      }
    }
    claimVoucherError = (error) => {
      console.log(error.message)
    }

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onCheckoutLoad={this.state.loading}
            putOrder={this.putOrder}
            createBill={this.createBill}
            pwaPayment={ this.pwaPayment }
            onChangeCheckoutHOC={this.onChangeCheckoutHOC}
            claimVoucher={this.claimVoucher}
            claimError={this.state.claimError}
            discountAmount={this.state.discountAmount}
          />
          {this.state.loading && <LoadingModal />}
        </>
      )
    }
  }

  const mapStateToProps = (state) => ({
    reduxData: state,
  })

  return connect(mapStateToProps, { updateData })(WithHOC)
}

export default HOC
