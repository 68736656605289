import React, { useEffect } from "react"
import {
  Text,
  Center,
  VStack,
  Box,
  Image,
  View,
  HStack,
} from "native-base"
import { Platform } from "react-native"
import Carousel from "react-native-snap-carousel"
import HomeHOC from "./action"
import _ from "lodash"

const Home = (props) => {
  useEffect(() => {
    props.getMobileBanners()
    props.getDownline()
    if( ![ 'android', 'ios'].includes( Platform.OS ) && window.location.pathname.includes( '/post-payment' )){
      let params = new URLSearchParams( window.location.search )
      let paymentInfo = {}
      for (let p of params) {
        paymentInfo[ p[0] ] = p[ 1 ]
      }
      props.navigation.navigate("post-payment", {
        queryParams: paymentInfo,
      })
    }
  }, [])
  return (
    <VStack flex={1} space={3}>
      <Center flex={3}>
        <Carousel
          layout="default"
          data={_.filter(props.banners, (e) => {
            return e.status.toLowerCase() === "active"
          })}
          sliderWidth={500}
          itemWidth={300}
          renderItem={({ item, index }) => {
            return (
              <Box
                borderRadius={20}
                width="100%"
                height="100%"
                overflow="hidden"
                bg="floralwhite"
              >
                <View flex={1}>
                  <Image
                    flex={1}
                    alt="Product Image"
                    source={{
                      uri: item.image_url
                        ? item.image_url
                        : "https://icons-for-free.com/download-icon-mountains+photo+photos+placeholder+sun+icon-1320165661388177228_512.png",
                    }}
                  />
                </View>
                <Box
                  position="absolute"
                  height="45%"
                  width="100%"
                  bg="#000000"
                  opacity="0.7"
                  bottom="0"
                />
                <Box
                  position="absolute"
                  height="45%"
                  width="100%"
                  bottom="0"
                  flex={1}
                  alignItems="flex-end"
                >
                  <VStack px={5} py={2} space={2}>
                    <Text bold color="white" fontSize="xl">
                      {item.banner_title}
                    </Text>
                    <Text fontSize="md" color="white">
                      {item.banner_description}
                    </Text>
                    {/* <Box flex={1} display="flex">
                      <Button
                        variant="link"
                        onPress={() => {
                          if (
                            item.navTo === "promotion" ||
                            item.navTo === "products"
                          )
                            props.navigation.navigate("order", {
                              screen: item.navTo,
                            })
                          else if (
                            item.navTo === "statistic" ||
                            item.navTo === "list"
                          )
                            props.navigation.navigate("downlines", {
                              screen: item.navTo,
                            })
                          else props.navigation.navigate(item.navTo)
                        }}
                      >
                      </Button>
                    </Box> */}
                  </VStack>
                </Box>
              </Box>
            )
          }}
        />
      </Center>
      <VStack flex={2}>
        <HStack flex={1}>
          <Box
            flex={1}
            m={2}
            borderRadius={20}
            alignItems="center"
            justifyContent="center"
          >
            <Center zIndex="-1" width="100%" height="100%" position="absolute">
              <Box
                width="100%"
                height="100%"
                borderRadius={20}
                bg={{
                  linearGradient: {
                    colors: ["#666", "#ff7f7d", "#666"],
                    start: [0.9, 0.0],
                    end: [0.1, 1],
                  },
                }}
                opacity={0.2}
              />
            </Center>
            <Text color="white" fontSize="sm" bold>
              Monthly Target
            </Text>
            <Text fontSize="xl" color="#ff7f7d" bold>
              RM520
            </Text>
            <Text color="white" fontSize="sm">
              ( RM1000 )
            </Text>
          </Box>
          <Box
            flex={1}
            m={2}
            borderRadius={20}
            alignItems="center"
            justifyContent="center"
          >
            <Center zIndex="-1" width="100%" height="100%" position="absolute">
              <Box
                width="100%"
                height="100%"
                borderRadius={20}
                bg={{
                  linearGradient: {
                    colors: ["#999", "#42f560", "#999"],
                    start: [0.9, 0.0],
                    end: [0.1, 1],
                  },
                }}
                opacity={0.2}
              />
            </Center>
            <Text color="white" fontSize="sm" bold>
              Account Status
            </Text>
            <Text color="#42f560" fontSize="xl" bold>
              ACTIVE
            </Text>
          </Box>
        </HStack>

        <HStack flex={1}>
          <Box
            flex={1}
            m={2}
            borderRadius={20}
            alignItems="center"
            justifyContent="center"
          >
            <Center zIndex="-1" width="100%" height="100%" position="absolute">
              <Box
                width="100%"
                height="100%"
                borderRadius={20}
                bg={{
                  linearGradient: {
                    colors: ["#999", "#ffee7d", "#999"],
                    start: [0.9, 0.0],
                    end: [0.1, 1],
                  },
                }}
                opacity={0.1}
              />
            </Center>
            <Text color="white" fontSize="sm" bold>
              Active Downlines
            </Text>
            <Text color="#ffee7d" fontSize="xl" bold>
              {
                props.reduxData.downlines 
                  ? props.reduxData.downlines.length
                  : 0
              }
            </Text>
          </Box>
          <Box
            flex={1}
            m={2}
            borderRadius={20}
            alignItems="center"
            justifyContent="center"
          >
            <Center zIndex="-1" width="100%" height="100%" position="absolute">
              <Box
                width="100%"
                height="100%"
                borderRadius={20}
                bg={{
                  linearGradient: {
                    colors: ["#999", "#72abd6", "#999"],
                    start: [0.9, 0.0],
                    end: [0.1, 1],
                  },
                }}
                opacity={0.1}
              />
            </Center>
            <Text color="white" fontSize="sm" bold>
              Downline Sales
            </Text>
            <Text color="#72abd6" fontSize="xl" bold>
              RM{" "}
              {
                props.reduxData.downlines
                  ? _.sumBy(props.reduxData.downlines, (e) => {
                      return e.sales["month"]
                    })
                  : 0
              }
            </Text>
          </Box>
        </HStack>
      </VStack>
    </VStack>
  )
}

export default HomeHOC(Home)
