import React, { useState, useContext } from "react"
import {
  FlatList,
  Box,
  VStack,
  Text,
  HStack,
  Heading,
  Center,
} from "native-base"
import { TouchableOpacity } from "react-native-gesture-handler"
import Information from "./information"

const listTextColor = "#fff"

const DownlineList = (props) => {
  const [isOpen, setShowModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})

  let downlines = useContext(props.route.params.DownlineContext)
  return (
    <VStack bgColor={ 'black' } height={ '100%' }>
      <Information
        item={selectedItem}
        isOpen={isOpen}
        setShowModal={setShowModal}
      />
      <Center>
        <Heading color={listTextColor}>Direct Downlines</Heading>
      </Center>
      <Box bg="#494949" borderRadius="20px" mx={5} mb={10}>
        {downlines && downlines.length > 0 ? (
          <FlatList
            data={downlines}
            keyExtractor={(item) => item.uid}
            renderItem={({ item }) => (
              <TouchableOpacity
                onPress={() => {
                  setShowModal(true)
                  setSelectedItem(item)
                }}
              >
                <HStack space={5} alignItems="center" p={3} py={5}>
                  <VStack w={125}>
                    <Text color={listTextColor} fontSize="md">
                      {item.username}
                    </Text>
                    <Text color={listTextColor} fontSize="xs">
                      {item.position}
                    </Text>
                  </VStack>
                  <Text
                    w={50}
                    fontSize="xs"
                    color={item.status === "active" ? "#52ff80" : "#ff0000"}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.status}
                  </Text>
                  <Text color={listTextColor}>
                    RM {item.sales["month"].toFixed(2)}
                  </Text>
                </HStack>
              </TouchableOpacity>
            )}
            ItemSeparatorComponent={(item) => {
              return (
                <Center>
                  <Box
                    w="95%"
                    borderBottomColor={listTextColor}
                    borderWidth={"1px"}
                  />
                </Center>
              )
            }}
          />
        ) : (
          <Center h={100}>
            <Text color="#fff"> No Record </Text>
          </Center>
        )}
      </Box>
    </VStack>
  )
}

export default DownlineList
