import React, { useEffect } from "react"
import { createStackNavigator } from "@react-navigation/stack"
import { createDrawerNavigator } from "@react-navigation/drawer"
import { Box } from "native-base"

import Home from "./Home"
import Order from "./Order"
import Profile from "./Profile"
import Downlines from "./Downlines"
import Notification from "./Notification"
import Cart from "./Cart"
import Checkout from "./Checkout"
import PaymentGateway from "./PaymentGateway"
import PostPayment from "./PostPayment"
import EditProfile from "./EditProfile"
import ChangePassword from "./ChangeCredential/ChangePassword"
import ChangeEmail from "./ChangeCredential/ChangeEmail"
import TopMenu from "../../components/TopMenu"
import TabMenu from "../../components/TabMenu"

const MainStack = createStackNavigator()
const Drawer = createDrawerNavigator()

const config = {
  animation: "spring",
  config: {
    stiffness: 1000,
    damping: 50,
    mass: 3,
    overshootClamping: false,
    restDisplacementThreshold: 0.01,
    restSpeedThreshold: 0.01,
  },
}

const MainTabScreen = () => {

  return (
    <>
      <TopMenu />
      <Box flex={1} mb="50px" bgColor={ 'black' }>
        <MainStack.Navigator
          screenOptions={{
            gestureEnabled: true,
            gestureDirection: "horizontal",
            transitionSpec: {
              open: config,
              close: config,
            },
          }}
          animation="fade"
        >
          <MainStack.Screen
            name="home"
            component={Home}
            options={{ headerShown: false }}
          />
          <MainStack.Screen
            name="order"
            component={Order}
            options={{ headerShown: false }}
          />
          <MainStack.Screen
            name="profile"
            component={Profile}
            options={{ headerShown: false }}
          />
          <MainStack.Screen
            name="downlines"
            component={Downlines}
            options={{ headerShown: false }}
          />
          <MainStack.Screen
            name="cart"
            component={Cart}
            options={{ headerShown: false }}
          />
          <MainStack.Screen
            name="checkout"
            component={Checkout}
            options={{ headerShown: false }}
          />
          <MainStack.Screen
            name="payment-gateway"
            component={PaymentGateway}
            options={{ headerShown: false }}
          />
          <MainStack.Screen
            name="post-payment"
            component={PostPayment}
            options={{ headerShown: false }}
          />
          <MainStack.Screen
            name="change-password"
            component={ChangePassword}
            options={{ headerShown: false }}
          />
          <MainStack.Screen
            name="change-email"
            component={ChangeEmail}
            options={{ headerShown: false }}
          />
          <MainStack.Screen
            name="edit-profile"
            component={EditProfile}
            options={{ headerShown: false }}
          />
        </MainStack.Navigator>
      </Box>
      <TabMenu />
    </>
  )
}

const Main = () => {

  return (
    <Drawer.Navigator drawerContent={(props) => <Notification {...props} />}>
      <Drawer.Screen name="mainScreen" component={ MainTabScreen } options={{ headerShown: false }} />
    </Drawer.Navigator>
  )
}

export default Main;
