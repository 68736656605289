import React from "react"
import {
  Modal,
  Button,
  Image,
  Center,
  VStack,
  HStack,
  Text,
  Box,
} from "native-base"
import { Dimensions } from "react-native"
import { LineChart } from "react-native-chart-kit"

const Information = (props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={() => props.setShowModal(false)}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header><Text bold fontSize="lg">Distributor Info</Text></Modal.Header>
        <Modal.Body>
          <VStack flex={1}>
            <VStack pr={1} space={2}>
              <Box>
                <HStack space={2}>
                  <Text flex={1} bold>
                    Name
                  </Text>
                  <Text flex={2}>{props.item.username}</Text>
                </HStack>
              </Box>
              <Box>
                <HStack space={2} alignItems="center">
                  <Text flex={1} bold>
                    Position
                  </Text>
                  <Text flex={2}>{props.item.position}</Text>
                </HStack>
              </Box>
              <Box>
                <HStack space={2}>
                  <Text flex={1} bold>
                    Contact No.
                  </Text>
                  <Text flex={2}>{props.item.whatsapp}</Text>
                </HStack>
              </Box>
              <Box>
                <HStack space={2}>
                  <Text flex={1} bold>
                    Address
                  </Text>
                  <Text flex={2}>{props.item.address}</Text>
                </HStack>
              </Box>
              <Box>
                <HStack space={2}>
                  <Text flex={1} bold>
                    Worker ID
                  </Text>
                  <Text flex={2}>{props.item.worker_id}</Text>
                </HStack>
              </Box>
              {/* <Box>
                <HStack space={2}>
                  <Text flex={1} bold>
                    License
                  </Text>
                  <Text flex={2}>{props.item.shop_license}</Text>
                </HStack>
              </Box>
              <Box>
                <HStack space={2} alignItems="center">
                  <Text flex={1} bold>
                    Shop Adress
                  </Text>
                  <Text flex={2}>{props.item.shop_address}</Text>
                </HStack>
              </Box> */}
              <Box>
                <HStack space={2}>
                  <Text flex={1} bold>
                    Status
                  </Text>
                  <Text
                    flex={2}
                    style={{ textTransform: "capitalize" }}
                    color={props.item.status === "active" ? "#52ff80" : "#ff0000"}
                  >
                    {props.item.status}
                  </Text>
                </HStack>
              </Box>
            </VStack>
            <VStack>
              <Center mt={3}>
                <HStack alignItems="center">
                  <Text fontSize="sm" bold>
                    {"Sales of this month: "}
                  </Text>
                  <Text fontSize="lg">
                    RM {props.item.sales?props.item.sales["month"].toFixed(2):"0.00"}
                  </Text>
                </HStack>
              </Center>
              <Center>
                <LineChart
                  data={{
                    labels: ["9/6", "9/13", "9/20", "9/27", "10/4", "10/11"],
                    datasets: [
                      {
                        data: [
                          Math.random() * 100,
                          Math.random() * 100,
                          Math.random() * 100,
                          Math.random() * 100,
                          Math.random() * 100,
                          Math.random() * 100,
                        ],
                      },
                    ],
                  }}
                  width={Dimensions.get("window").width * 0.7} // from react-native
                  height={220}
                  yAxisLabel="$"
                  yAxisSuffix="k"
                  yAxisInterval={1} // optional, defaults to 1
                  chartConfig={{
                    backgroundColor: "#E26A00",
                    backgroundGradientFrom: "#FCAF38",
                    backgroundGradientTo: "#FF9C08",
                    decimalPlaces: 2,
                    color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                    labelColor: (opacity = 1) =>
                      `rgba(255, 255, 255, ${opacity})`,
                    style: {
                      borderRadius: 16,
                    },
                    propsForDots: {
                      r: "6",
                      strokeWidth: "2",
                      stroke: "#ffa726",
                    },
                  }}
                  bezier
                  style={{
                    marginVertical: 8,
                    borderRadius: 16,
                  }}
                />
              </Center>
            </VStack>
          </VStack>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button
              variant="ghost"
              colorScheme="blueGray"
              onPress={() => {
                props.setShowModal(false)
              }}
            >
              Close
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}

export default Information
