import React from "react"
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs"
import OrderHistory from "./OrderHistory"
import Products from "./Products"
import Materials from "./Material"
import OrderHOC from "./actions"

const Tab = createMaterialTopTabNavigator()

const Order = (props) => {
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarLabelStyle: { fontSize: 12 },
        tabBarStyle: { backgroundColor: "#222222" },
        tabBarActiveTintColor: "white",
        tabBarInactiveTintColor: "white",
      }}
    >
      <Tab.Screen name="order history" component={OrderHistory} />
      <Tab.Screen name="products" component={Products} />
      {props.reduxData.user && props.reduxData.user.role === "Branch" && (
        <Tab.Screen name="materials" component={Materials} />
      )}
    </Tab.Navigator>
  )
}

export default OrderHOC(Order)
