import React, { useState } from "react"
import { MaterialIcons } from "@expo/vector-icons"
import {
  Text,
  Center,
  VStack,
  Button,
  Input,
  FormControl,
  Icon,
  useToast,
} from "native-base"
import { sendPasswordResetEmail, getAuth } from "firebase/auth"
import LoadingModal from "../../components/LoadingModal"
const logo = require("../../assets/logo.png")

const ForgetPassword = (props) => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [error, setError] = useState({})

  const auth = getAuth()
  const toast = useToast()
  const resetPassword = () => {
    setLoading( true )
    sendPasswordResetEmail( auth, email ).then(() => {
      setLoading(false)
      props.navigation.goBack()
      toast.show({
        title: 'Password Reset',
        description: 'Please check your email to reset your password',
        status: "success",
        placement: "bottom",
      })
    }).catch( error  => {
      setLoading(false)
      toast.show({
        title: 'error.message',
        status: "danger",
        placement: "bottom",
      })
    })
  }

  return (
    <VStack bg={ 'black' } height={ '100%' }>
      <Center flex={1}>
        <VStack
          space={3}
          width="90%"
          bg="rgba(255,255,255,0.2)"
          p={5}
          borderRadius={20}
        >
          <Text bold fontSize="lg" color="white">
            Terlupa kata laluan
          </Text>
          <Text color="#fff">
            Sila key in emel anda untuk set password yang baru.
          </Text>
          <FormControl isInvalid={"email" in error}>
            <Input
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="badge" />}
                  size="md"
                  m={2}
                  _light={{
                    color: "gray.500",
                  }}
                  _dark={{
                    color: "gray.500",
                  }}
                />
              }
              variant="filled"
              placeholder="Email"
              onChangeText={(value) => {
                setError({})
                setEmail(value.trim())
              }}
            />
            <FormControl.ErrorMessage
              mb={1}
              mt={0}
              _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
            >
              {error.email}
            </FormControl.ErrorMessage>
          </FormControl>
          <Button
            colorScheme="teal"
            p={4}
            onPress={() => {
              if (email !== "") {
                resetPassword()
              } else {
                setError({ email: "Sila masukkan emel anda" })
              }
            }}
          >
            Hantar
          </Button>
        </VStack>
        {loading && <LoadingModal />}
      </Center>
    </VStack>
  )
}

export default ForgetPassword
