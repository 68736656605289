import {
  VStack,
  Box,
  Center,
  Image,
  HStack,
  Text,
  ScrollView,
  IconButton,
  Icon,
  Button,
  useToast,
  Avatar,
} from "native-base"
import { LineChart, PieChart } from "react-native-chart-kit"
import { Dimensions, TouchableOpacity } from "react-native"
import { MaterialIcons } from "@expo/vector-icons"
import React, { useState, useEffect } from "react"
import { getAuth } from "firebase/auth"

import { sales } from "../../../data/sales"
import { user } from "../../../data/user"
import profileHOC from "./actions"

// const platinum = ["#A0B2C6", "#C1C4C7"]
// const gold = ["#FFD700", "#FFE554"]
// const silver = ["#E5E5E5", "#F7F7F7"]
const infoColor = "#fff"
const chartConfig = {
  backgroundColor: "#e26a00",
  backgroundGradientFrom: "#fb8c00",
  backgroundGradientTo: "#ffa726",
  decimalPlaces: 2, // optional, defaults to 2dp
  color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
}

const sort = (data, accessor) => {
  let tmp = data.sort((a, b) => {
    if (a[accessor] > b[accessor]) {
      return -1
    }
    if (a[accessor] < b[accessor]) {
      return 1
    }
    return 0
  })
  return tmp
}

const pieDataPrep = (data, accessor) => {
  let tmp = sort(data, accessor)
  let colorSet = ["#27612a", "#388e3c", "#8bc34a", "#d5e356", "#fff59d"]
  tmp.map((item, index) => {
    tmp[index] = {
      ...item,
      color: colorSet[index],
      legendFontColor: "#FFF",
      legendFontSize: "15",
    }
  })
  return tmp
}

const Profile = (props) => {
  const auth = getAuth()
  const toast = useToast()
  const [isEditMode, setEditMode] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [user, setUser] = useState({})

  useEffect(() => {
    // console.log(props.reduxData.user)
    setUser(props.reduxData.user)
  }, [props.reduxData.user])

  const logOut = () => {
    auth
      .signOut()
      .then(() => {
        props.navigation.navigate("login")
        props.clearData()
        toast.show({
          title: "Signed Out",
          status: "success",
          placement: "bottom",
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
  return (
    <ScrollView bgColor={ 'black' }>
      <VStack>
        <Center p={2}>
          {user ? (
            <Box
              borderRadius="15px"
              width="95%"
              p={5}
              bg={{
                linearGradient: {
                  colors: ["#000", "#000047", "#000"],
                  start: [0, 1],
                  end: [1, 0],
                },
              }}
              borderWidth={1}
              borderColor={"#000070"}
            >
              <VStack space={3}>
                <Center>
                  <Text
                    color={infoColor}
                    fontSize="12px"
                    textTransform="capitalize"
                  >
                    {user.level}
                  </Text>
                  <Avatar
                    bg="yellow.500"
                    alignSelf="center"
                    size="xl"
                    source={{
                      uri: "https://st3.depositphotos.com/6672868/13701/v/380/depositphotos_137014128-stock-illustration-user-profile-icon.jpg?forcejpeg=true",
                    }}
                    borderWidth={3}
                    borderColor={
                      user.level === "platinum"
                        ? "#d3d6d8"
                        : user.level === "gold"
                        ? "#d4af37"
                        : user.level === "silver"
                        ? "#aaa9ad"
                        : "#a97142"
                    }
                  >
                    AJ
                  </Avatar>
                </Center>
                <Center>
                    <Text
                      color={infoColor}
                      fontSize="12px"
                      textTransform="capitalize"
                      textAlign="center"
                    >
                      {user.status}
                    </Text>
                  <VStack>
                    <Text textAlign="center" color={infoColor} fontSize="md">
                      {user.position}
                    </Text>
                    <Text
                      textAlign="center"
                      color={infoColor}
                      fontSize="xl"
                      bold
                    >
                      {user.username}
                    </Text>
                  </VStack>
                </Center>
                <Center>
                  <VStack>
                    <Text textAlign="center" color={infoColor}>
                      Total Sales (Month)
                    </Text>
                    <Text textAlign="center" color={infoColor} fontSize="4xl">
                      RM {user.sales ? user.sales.month : 0}
                    </Text>
                  </VStack>
                  <HStack space={5}>
                    <VStack>
                      <Text textAlign="center" color={infoColor}>
                        Total Sales (Day)
                      </Text>
                      <Text textAlign="center" color={infoColor} fontSize="2xl">
                        RM {user.sales ? user.sales.day : 0}
                      </Text>
                    </VStack>
                    <VStack>
                      <Text textAlign="center" color={infoColor}>
                        Total Sales (Year)
                      </Text>
                      <Text textAlign="center" color={infoColor} fontSize="2xl">
                        RM {user.sales ? user.sales.year : 0}
                      </Text>
                    </VStack>
                  </HStack>
                </Center>
                <HStack alignItems="center" space={2}>
                  <Icon
                    as={MaterialIcons}
                    name="call"
                    color={infoColor}
                    size="xs"
                  />
                  <Text color={infoColor} fontSize="lg">
                    {user.whatsapp}
                  </Text>
                </HStack>
                <HStack space={2}>
                  <Icon
                    as={MaterialIcons}
                    name="storefront"
                    color={infoColor}
                    size="xs"
                    mt={1}
                  />
                  <VStack flex={1} space={1}>
                    <Text color={infoColor} fontSize="lg">
                      {user.address}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            </Box>
          ) : (
            <Text> Please Login </Text>
          )}
        </Center>
        {/* <Center>
          <Button
            my={4}
            _text={{ fontSize: "md" }}
            borderRadius={20}
            colorScheme="teal"
          >
            Generate Individual Sales Report
          </Button>
        </Center>
        <Center>
          <Text fontSize="xl" color="white" pl={3} bold>
            Weekly Sales
          </Text>
          <LineChart
            data={{
              labels: ["9/6", "9/13", "9/20", "9/27", "10/4", "10/11"],
              datasets: [
                {
                  data: [
                    Math.random() * 100,
                    Math.random() * 100,
                    Math.random() * 100,
                    Math.random() * 100,
                    Math.random() * 100,
                    Math.random() * 100,
                  ],
                },
              ],
            }}
            width={Dimensions.get("window").width * 0.9} // from react-native
            height={220}
            yAxisLabel="$"
            yAxisSuffix="k"
            yAxisInterval={1} // optional, defaults to 1
            chartConfig={{
              backgroundColor: "#E26A00",
              backgroundGradientFrom: "#ff4f8a",
              backgroundGradientTo: "#ffe84f",
              decimalPlaces: 2,
              color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
              labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
              style: {
                borderRadius: 16,
              },
              propsForDots: {
                r: "6",
                strokeWidth: "2",
                stroke: "#ffa726",
              },
            }}
            bezier
            style={{
              marginVertical: 8,
              borderRadius: 16,
            }}
          />
        </Center>
        <Center pb={3}>
          <Text fontSize="xl" color="white" pl={3} pb={2} bold>
            Product Sales
          </Text>
          <Box
            bg={{
              linearGradient: {
                colors: ["#ffe84f", "#4fffe8"],
                start: [0, 0.5],
                end: [1, 0],
              },
            }}
            rounded="xl"
          >
            <PieChart
              data={pieDataPrep(sales, "sales")}
              width={Dimensions.get("window").width * 0.9}
              height={220}
              chartConfig={chartConfig}
              accessor={"sales"}
              center={[10, 0]}
            />
          </Box>
        </Center> */}
        <VStack>
          <Center>
            <Button
              minWidth="250px"
              my={2}
              _text={{ fontSize: "md" }}
              borderRadius={20}
              variant="outline"
              colorScheme="darkBlue"
              onPress={() => {
                props.navigation.navigate("edit-profile")
              }}
            >
              Update Profile
            </Button>
          </Center>
          <Center>
            <Button
              minWidth="250px"
              my={1}
              _text={{ fontSize: "md" }}
              borderRadius={20}
              colorScheme="darkBlue"
              variant="outline"
              onPress={() => {
                props.navigation.navigate("change-email")
              }}
            >
              Change Email
            </Button>
          </Center>
          <Center>
            <Button
              minWidth="250px"
              my={1}
              _text={{ fontSize: "md" }}
              borderRadius={20}
              colorScheme="darkBlue"
              variant="outline"
              onPress={() => {
                props.navigation.navigate("change-password")
              }}
            >
              Change Password
            </Button>
          </Center>
          <Center>
            <Button
              minWidth="250px"
              my={2}
              _text={{ fontSize: "md" }}
              borderRadius={20}
              colorScheme="danger"
              variant="outline"
              onPress={() => {
                logOut()
              }}
            >
              Sign Out
            </Button>
          </Center>
        </VStack>
      </VStack>
    </ScrollView>
  )
}

export default profileHOC(Profile)
