import { VStack, Text, FlatList, Box } from "native-base"
import React from "react"

const Notification = () => {
  const data = [
    {
      message:
        "Check out the new 10.10 sales promotion and grab one of those for yourself.",
      date: "15/01/2021",
      time: "11:30am",
    },
    {
      message:
        "Your account has been inactivated as you have not reach the monthly target for the last month.",
      date: "15/04/2021",
      time: "12:30am",
    },
    {
      message: "New Musang King Ice Cream. Be the first one to taste it out.",
      date: "10/10/2021",
      time: "1:30pm",
    },
    {
      message:
        "Monthly Commision has been tabulated, check on the monthly report",
      date: "24/10/2021",
      time: "2:30pm",
    },
  ]

  const textColor = "#fff"
  return (
    <>
      <Box bg="gray.900" px={5} py={3}>
        <Text fontSize="2xl" color="white" bold>
          {" "}
          Notifications
        </Text>
      </Box>
      <Box bg="gray.800" flex={1}>
        <FlatList
          data={data}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <>
              <Box pb={3} pr={4}>
                <Text
                  bold
                  fontSize="md"
                  pl={6}
                  py={1}
                  color={textColor}
                  textAlign="justify"
                >
                  {item.message}
                </Text>
                <Text fontSize="xs" pl={6} color={textColor}>
                  {item.date + " " + item.time}
                </Text>
              </Box>
              <Box borderColor="white" borderBottomWidth={1} flex={1} />
            </>
          )}
        />
      </Box>
    </>
  )
}

export default Notification
