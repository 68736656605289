import React, { Component } from 'react'

import { connect } from "react-redux"
import { updateData } from '../../../action'
import { Get, Post } from '../../../utils/axios'
import LoadingModal from '../../../components/LoadingModal'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state={
      loading: false,
      cart: [],
    }

    load = param => this.setState({ loading: param })
    onChangeChartHOC = ( val, context ) => this.setState({ [ context ]: val})

    getCart = ( ) => Get(
      `/getCartItems?order_by=${ this.props.reduxData.uid }`,
      this.getCartSuccess,
      this.getCartError,
      this.load
    )
    getCartSuccess = payload => {
      console.log( payload.data )
      this.props.updateData('cart', payload.data )
    }
    getCartError = error => { console.log( error.message )}

    updateCart = ( item ) =>Post(
      `/updateCartQuantity`,
      {
        order_by: this.props.reduxData.uid,
        item_id: item.item_id,
        quantity: item.quantity
      },
      this.updateCartSuccess,
      this.updateCartError,
      () => {}
    )
    updateCartSuccess = payload => console.log( payload )
    updateCartError = error => console.log(error)

    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            onCartLoad={ this.state.loading }
            
            getCart={ this.getCart}
            updateCart={ this.updateCart }
            onChangeChartHOC={ this.onChangeChartHOC }
          />
          { this.state.loading && <LoadingModal />}
        </>
      )
    }
  }

  const mapStateToProps = (state) => ({
    reduxData: state,
  })

  return connect(mapStateToProps, { updateData })(WithHOC)
}


export default HOC