import React, { Component } from "react"

import { Post } from "../../../utils/axios"
import { connect } from "react-redux"
import { updateData } from "../../../action"

import LoadingModal from '../../../components/LoadingModal'
import _ from "lodash"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false
    }

    load = (param) => this.setState({ loading: param })

    updateUserProfile = (data) => { 
      Post(
        `/updateUser`,
        data,
        (payload)=>this.updateUserProfileSuccess(payload, data),
        this.updateUserProfileError,
        this.load
      )
    }

    updateUserProfileSuccess = (payload, data) => {
      let tmp = _.cloneDeep(data)
      delete tmp.uid
      this.props.updateData('user', tmp )
      this.props.navigation.goBack()
    }
    updateUserProfileError = (err) => console.log(err)

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            updateUserProfile={this.updateUserProfile}
          />
          {this.state.loading && <LoadingModal />}
        </>
      )
    }
  }

  const mapStateToProps = (state) => ({
    reduxData: state,
  })

  return connect(mapStateToProps, { updateData })(WithHOC)
}

export default HOC
