import React, { Component } from 'react'
import { connect } from "react-redux"

import { Post, Get } from '../../../utils/axios'
import { updateData } from '../../../action'
import LoadingModal from "../../../components/LoadingModal"

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state={
      loading: false
    }
    
    load = param => this.setState({ loading: param })

    addToCart = ( id, quantity ) => {
      Post(
      `/addCartItem`,
      {
        order_by: this.props.reduxData.uid,
        item: {
          item_id: id,
          quantity: quantity ? quantity : 1
        }
      },
      this.addToCartSuccess,
      this.addToCartError,
      this.load
    )}
    addToCartSuccess = ( payload ) => {
      this.getCart( this.props.reduxData.uid )
    }
    addToCartError = error => console.log(error)

    getCart = ( id ) => Get(
      `/getCartItems?order_by=${ id }`,
      this.getCartSuccess,
      this.getCartError,
      this.load
    )
    getCartSuccess = payload => {
      this.props.updateData('cart', payload.data )
    }
    getCartError = error => { console.log( error.message )}

    getProduct = () => Get(
      `/getProduct`,
      this.getProductSuccess,
      this.getProductError,
      this.load
    )
    getProductSuccess = payload => {
      this.props.updateData('product', payload.data.list )
    }
    getProductError = error => { console.log( error.message )}
    
    getMaterial = () => Get(
      `/getMaterial`,
      this.getMaterialSuccess,
      this.getMaterialError,
      this.load
    )
    getMaterialSuccess = payload => {
      this.props.updateData('material', payload.data )
    }
    getMaterialError = error => { console.log( error.message )}

    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }

            getProduct={ this.getProduct }
            getMaterial={ this.getMaterial }
            addToCart={ this.addToCart }
          />
          {this.state.loading && <LoadingModal />}
        </>
      )
    }
  }

  const mapStateToProps = (state) => ({
    reduxData: state,
  })

  return connect(mapStateToProps, { updateData })(WithHOC)
}


export default HOC