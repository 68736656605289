import React, { useState, useEffect } from "react"
import { MaterialIcons } from "@expo/vector-icons"
import {
  Text,
  Center,
  VStack,
  Image,
  Button,
  Input,
  FormControl,
  Icon,
  useToast,
} from "native-base"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import LoadingModal from '../../components/LoadingModal'
import { Get } from '../../utils/axios'
import withLoginHOC from "./action"
const logo = require("../../assets/logo.png")

const Login = (props) => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [error, setError] = useState({})
  const [password, setPassword] = useState("")
  const toast = useToast()

  useEffect(() => {
    props.authenticateUser()
  }, [])

  const auth = getAuth()
  const dataValidation = () => {
    let checkStatus = true
    setError({})
    if (email === "") {
      checkStatus = false
      setError((err) => ({
        ...err,
        email: "Please enter your email address",
      }))
    }
    if (password === "") {
      checkStatus = false
      setError((err) => ({
        ...err,
        password: "Please enter your password",
      }))
    }
    if (checkStatus) {
      setLoading(true)
      signIn()
    }
  }

  const signIn = () => {
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user
      props.updateData('uid', user.uid )
      Get(
        `/getUser?uid=${ user.uid }`,
        payload => {
          props.updateData('user', payload.data )
          props.getCart( user.uid )
          props.navigation.navigate("main")
          toast.show({
            title: 'Signed In',
            status: "success",
            placement: "bottom",
          })
        },
        error => {
          toast.show({
            description: error.message,
            status: "warning",
            placement: "bottom",
          })
        },
        param => setLoading( param )
      )
    })
    .catch((error) => {
      setLoading(false)
      const errorMessage = error.message
      if ( errorMessage.includes('user-not-found')){
        toast.show({
          title: 'User not found',
          status: "warning",
          placement: "bottom",
        })
      } else if( errorMessage.includes('wrong-password')){
        toast.show({
          title: 'Wrong Password',
          status: "warning",
          placement: "bottom",
        })
      } else {
        toast.show({
          title: errorMessage,
          status: "warning",
          placement: "bottom",
        })
      }
    })
  }

  return (
    <Center flex={1}>
      <Image source={logo} width={200} height={200} alt="logo" mb={10} />
      <VStack space={3} width="80%">
        <Text bold fontSize="lg" color="white">
          Login
        </Text>
        <FormControl isInvalid={"email" in error}>
          <Input
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="badge" />}
                size="md"
                m={2}
                _light={{
                  color: "gray.500",
                }}
                _dark={{
                  color: "gray.500",
                }}
              />
            }
            variant="filled"
            placeholder="Email"
            onChangeText={(value) => setEmail(value.trim())}
          />
          <FormControl.ErrorMessage
            mb={1}
            mt={0}
            _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
          >
            {error.email}
          </FormControl.ErrorMessage>
        </FormControl>
        <FormControl isInvalid={"password" in error}>
          <Input
            type="password"
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="lock" />}
                size="md"
                m={2}
                _light={{
                  color: "gray.500",
                }}
                _dark={{
                  color: "gray.500",
                }}
              />
            }
            variant="filled"
            placeholder="Kata Laluan"
            onChangeText={(value) => setPassword(value)}
          />
          <FormControl.ErrorMessage
            mb={1}
            mt={0}
            _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
          >
            {error.password}
          </FormControl.ErrorMessage>
        </FormControl>
        <Button
          colorScheme="teal"
          p={4}
          onPress={() => {
            dataValidation()
          }}
        >
          Log In
        </Button>
        <Button
          colorScheme="danger"
          p={4}
          onPress={() => {
            props.navigation.navigate("forget_password")
          }}
        >
          Forget Password
        </Button>
      </VStack>
      { loading && <LoadingModal/> }
    </Center>
  )
}

export default withLoginHOC(Login)
