import { Button, HStack, Text, VStack, Icon, View } from "native-base"
import { MaterialIcons } from "@expo/vector-icons"
import React, { useEffect, useState } from "react"
import { useNavigation } from "@react-navigation/native"
import { connect } from "react-redux"
import { updateData } from "../../action"

const size = "sm"
const font_size = "10px"
const background = "white"
const color = "#9E9E9E"
const active_color = "#4166f5"

const TabMenu = (props) => {
  const [currentRoute, setCurrentRoute] = useState("home")
  const navigation = useNavigation()
  useEffect(() => {
    setCurrentRoute(
      props.reduxData.menuTitle
    )
  },[ props.reduxData.menuTitle ])
  return (
    <View
      flex={1}
      width={"100%"}
      position="absolute"
      height={"50px"}
      backgroundColor="#fff"
      bottom={0}
    >
      <HStack
        alignItems="center"
        borderTopWidth={1}
        borderColor={active_color}
      >
        <Button
          borderRadius={0}
          backgroundColor={background}
          flex={1}
          px={0}
          bgColor="black"
          onPress={() => {
            navigation.navigate("home")
            props.updateData('menuTitle', "home" )
          }}
        >
          <VStack alignItems="center">
            <Icon
              size={size}
              color={currentRoute === "home" ? active_color : color}
              as={<MaterialIcons name={"home"} />}
            />
            <Text
              fontSize={font_size}
              color={currentRoute === "home" ? active_color : color}
            >
              Home
            </Text>
          </VStack>
        </Button>
        <Button
          borderRadius={0}
          backgroundColor={background}
          flex={1}
          px={0}
          bgColor="black"
          onPress={() => {
            navigation.navigate("order")
            props.updateData('menuTitle', "order" )
          }}
        >
          <VStack alignItems="center">
            <Icon
              size={size}
              color={
                currentRoute === "order"
                  ? active_color
                  : color
              }
              as={<MaterialIcons name={"storefront"} />}
            />
            <Text
              fontSize={font_size}
              color={
                currentRoute === "order"
                  ? active_color
                  : color
              }
            >
              Order
            </Text>
          </VStack>
        </Button>
        <Button
          borderRadius={0}
          backgroundColor={background}
          flex={1}
          px={0}
          bgColor="black"
          onPress={() => {
            navigation.navigate("downlines")
            props.updateData('menuTitle', "downlines" )
          }}
        >
          <VStack alignItems="center">
            <Icon
              size={size}
              color={
                currentRoute === "downlines"
                  ? active_color
                  : color
              }
              as={<MaterialIcons name={"people-outline"} />}
            />
            <Text
              fontSize={font_size}
              color={
                currentRoute === "downlines"
                  ? active_color
                  : color
              }
            >
              Downlines
            </Text>
          </VStack>
        </Button>
        <Button
          borderRadius={0}
          backgroundColor={background}
          flex={1}
          px={0}
          bgColor="black"
          onPress={() => {
            navigation.navigate("profile")
            props.updateData('menuTitle', "profile" )
          }}
        >
          <VStack alignItems="center">
            <Icon
              size={size}
              color={currentRoute === "profile" ? active_color : color}
              as={<MaterialIcons name={"person-outline"} />}
            />
            <Text
              fontSize={font_size}
              color={currentRoute === "profile" ? active_color : color}
            >
              Profile
            </Text>
          </VStack>
        </Button>
      </HStack>
    </View>
  )
}

const mapStateToProps = (state) => ({
  reduxData: state,
})

export default connect(mapStateToProps, { updateData })(TabMenu)
