import React, { Component } from "react"
import { connect } from "react-redux"
import _ from "lodash"

import { Get } from "../../../../utils/axios"
import { updateData } from "../../../../action"
import LoadingModal from "../../../../components/LoadingModal"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
    }

    load = (param) => this.setState({ loading: param })

    getOrderHistory = () =>
      Get(
        `/getHistoryOrder?order_by=${this.props.reduxData.uid}`,
        this.getOrderHistorySuccess,
        this.getOrderHistoryError,
        this.load
      )
    getOrderHistorySuccess = (payload) => {
      let tmp = _.orderBy(
        payload.data.history,
        (o) => {
          return new Date(o.transaction_date)
        },
        ["desc"]
      )
      this.props.updateData("orderHistory", tmp)
    }
    getOrderHistoryError = (error) => {
      console.log(error.message)
    }

    getPendingPaymentHistory = () =>
      Get(
        `/getPendingPaymentHistory?order_by=${this.props.reduxData.uid}`,
        this.getPendingPaymentHistorySuccess,
        this.getPendingPaymentHistoryError,
        this.load
      )
    getPendingPaymentHistorySuccess = (payload) => {
      let tmp = _.orderBy(
        payload.data.history,
        (o) => {
          return new Date(o.order_date)
        },
        ["desc"]
      )
      this.props.updateData("pendingPaymentHistory", tmp)
    }
    getPendingPaymentHistoryError = (error) => {
      console.log(error)
    }

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadOrderHistory={ this.state.loading }
            getOrderHistory={this.getOrderHistory}
            getPendingPaymentHistory={this.getPendingPaymentHistory}
          />
          {this.state.loading && <LoadingModal />}
        </>
      )
    }
  }

  const mapStateToProps = (state) => ({
    reduxData: state,
  })

  return connect(mapStateToProps, { updateData })(WithHOC)
}

export default HOC
